import { FC } from 'react'

import { getImage } from '../../utils/getImage'

type ResponsiveImageProps = {
  urls: string[]
  alt: string
  className?: string
  loading?: 'eager' | 'lazy'
  fetchPriority?: 'auto' | 'low' | 'high'
}

export const ResponsiveImage: FC<ResponsiveImageProps> = ({
  urls,
  alt,
  className,
  loading = 'eager',
  fetchPriority = 'auto'
}) => {
  if (!urls?.length) return null
  const { small, medium, large } = getImage(urls)

  return (
    <picture>
      {large && (
        <source
          srcSet={large}
          media="(min-width: 1200px)"
        />
      )}
      {medium && (
        <source
          srcSet={medium}
          media="(min-width: 375px) and (max-width: 1199px)"
        />
      )}
      <img
        fetchPriority={fetchPriority}
        loading={loading}
        className={className}
        src={medium || large || small}
        alt={alt}
      />
    </picture>
  )
}
