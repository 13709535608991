import { LocalStorage, LocalStorageVoccab } from '@app/infrastructure/storage/localStorage'
import { dayjs, getDiffInHrs } from '@shared/helpers/dayjsExtended'
import { SIGN_UP } from '@shared/helpers/routes'

import { useAuthStore } from './AuthStore'

// @INFO: store expiry time for the the token on BE it is 7 days, on FE it is 4 hrs LESS
// to make user session smooth without 401 error
const TOKEN_EXPIRATION_OFFSET_HRS = 4

class AuthorizationService {
  private static instance: AuthorizationService
  private isAccessTokenExpirationValidated = false

  public static getInstance(): AuthorizationService {
    if (!AuthorizationService.instance) {
      AuthorizationService.instance = new AuthorizationService()
    }
    return AuthorizationService.instance
  }

  public getStoreToken() {
    return useAuthStore.getState().token
  }

  public updateToken(token: string) {
    useAuthStore.setState({
      token
    })

    LocalStorage.save(
      LocalStorageVoccab.accessTokenExpiration,
      dayjs().add(7, 'day').subtract(TOKEN_EXPIRATION_OFFSET_HRS, 'hour').valueOf()
    )

    LocalStorage.save(LocalStorageVoccab.accessToken, token)
  }

  public validateLocalTokenTimestamp() {
    if (this.isAccessTokenExpirationValidated) return

    const timeDifference = getDiffInHrs(LocalStorage.load(LocalStorageVoccab.accessTokenExpiration))
    if (timeDifference < TOKEN_EXPIRATION_OFFSET_HRS) {
      this.isAccessTokenExpirationValidated = true
      this.signOutAttempt()
    }
  }

  public signOutAttempt() {
    useAuthStore.setState({
      token: ''
    })
    this.isAccessTokenExpirationValidated = false
    LocalStorage.remove(LocalStorageVoccab.accessToken)
    LocalStorage.remove(LocalStorageVoccab.accessTokenExpiration)
    window.location.href = SIGN_UP
  }
}

const authServiceConstructor = AuthorizationService.getInstance()

export default authServiceConstructor
