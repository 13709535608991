export const AVAILABLE_NOW_MIN_RENT_DAYS = 2

export type TTimestampValue = { hour: number; minutes: number }

export const TIMESTAMPS = new Map<string, TTimestampValue>([
  [
    '1:00 AM',
    {
      hour: 1,
      minutes: 0
    }
  ],
  [
    '1:30 AM',
    {
      hour: 1,
      minutes: 30
    }
  ],
  [
    '2:00 AM',
    {
      hour: 2,
      minutes: 0
    }
  ],
  [
    '2:30 AM',
    {
      hour: 2,
      minutes: 30
    }
  ],
  [
    '3:00 AM',
    {
      hour: 3,
      minutes: 0
    }
  ],
  [
    '3:30 AM',
    {
      hour: 3,
      minutes: 30
    }
  ],
  [
    '4:00 AM',
    {
      hour: 4,
      minutes: 0
    }
  ],
  [
    '4:30 AM',
    {
      hour: 4,
      minutes: 30
    }
  ],
  [
    '5:00 AM',
    {
      hour: 5,
      minutes: 0
    }
  ],
  [
    '5:30 AM',
    {
      hour: 5,
      minutes: 30
    }
  ],
  [
    '6:00 AM',
    {
      hour: 6,
      minutes: 0
    }
  ],
  [
    '6:30 AM',
    {
      hour: 6,
      minutes: 30
    }
  ],
  [
    '7:00 AM',
    {
      hour: 7,
      minutes: 0
    }
  ],
  [
    '7:30 AM',
    {
      hour: 7,
      minutes: 30
    }
  ],
  [
    '8:00 AM',
    {
      hour: 8,
      minutes: 0
    }
  ],
  [
    '8:30 AM',
    {
      hour: 8,
      minutes: 30
    }
  ],
  [
    '9:00 AM',
    {
      hour: 9,
      minutes: 0
    }
  ],
  [
    '9:30 AM',
    {
      hour: 9,
      minutes: 30
    }
  ],
  [
    '10:00 AM',
    {
      hour: 10,
      minutes: 0
    }
  ],
  [
    '10:30 AM',
    {
      hour: 10,
      minutes: 30
    }
  ],
  [
    '11:00 AM',
    {
      hour: 11,
      minutes: 0
    }
  ],
  [
    '11:30 AM',
    {
      hour: 11,
      minutes: 30
    }
  ],
  [
    '12:00 PM',
    {
      hour: 12,
      minutes: 0
    }
  ],
  [
    '12:30 PM',
    {
      hour: 12,
      minutes: 30
    }
  ],
  [
    '1:00 PM',
    {
      hour: 13,
      minutes: 0
    }
  ],
  [
    '1:30 PM',
    {
      hour: 13,
      minutes: 30
    }
  ],
  [
    '2:00 PM',
    {
      hour: 14,
      minutes: 0
    }
  ],
  [
    '2:30 PM',
    {
      hour: 14,
      minutes: 30
    }
  ],
  [
    '3:00 PM',
    {
      hour: 15,
      minutes: 0
    }
  ],
  [
    '3:30 PM',
    {
      hour: 15,
      minutes: 30
    }
  ],
  [
    '4:00 PM',
    {
      hour: 16,
      minutes: 0
    }
  ],
  [
    '4:30 PM',
    {
      hour: 16,
      minutes: 30
    }
  ],
  [
    '5:00 PM',
    {
      hour: 17,
      minutes: 0
    }
  ],
  [
    '5:30 PM',
    {
      hour: 17,
      minutes: 30
    }
  ],
  [
    '6:00 PM',
    {
      hour: 18,
      minutes: 0
    }
  ],
  [
    '6:30 PM',
    {
      hour: 18,
      minutes: 30
    }
  ],
  [
    '7:00 PM',
    {
      hour: 19,
      minutes: 0
    }
  ],
  [
    '7:30 PM',
    {
      hour: 19,
      minutes: 30
    }
  ],
  [
    '8:00 PM',
    {
      hour: 20,
      minutes: 0
    }
  ],
  [
    '8:30 PM',
    {
      hour: 20,
      minutes: 30
    }
  ],
  [
    '9:00 PM',
    {
      hour: 21,
      minutes: 0
    }
  ],
  [
    '9:30 PM',
    {
      hour: 21,
      minutes: 30
    }
  ],
  [
    '10:00 PM',
    {
      hour: 22,
      minutes: 0
    }
  ],
  [
    '10:30 PM',
    {
      hour: 22,
      minutes: 30
    }
  ],
  [
    '11:00 PM',
    {
      hour: 23,
      minutes: 0
    }
  ],
  [
    '11:30 PM',
    {
      hour: 23,
      minutes: 30
    }
  ],
  [
    '12:00 AM',
    {
      hour: 0,
      minutes: 0
    }
  ],
  [
    '12:30 AM',
    {
      hour: 0,
      minutes: 30
    }
  ]
])
