import React, { FC } from 'react'
import { Popover } from '@mui/material'

import { TDateTimePickerTypes } from './DateTimePicker'

type PopoverWrapperProps = {
  anchorEl: HTMLElement | null
  onClose: () => void
  children: React.ReactNode
  type?: TDateTimePickerTypes
}

const PopoverWrapper: FC<PopoverWrapperProps> = ({ anchorEl, onClose, children, type }) => {
  const pickupDate = type === TDateTimePickerTypes.START
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: pickupDate ? 'left' : 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: pickupDate ? 'left' : 'right'
      }}
      sx={{
        '& .MuiPaper-root': {
          marginTop: '3px',
          minWidth: '320px',
          borderRadius: '8px',
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.08)'
        }
      }}
    >
      {children}
    </Popover>
  )
}

export default PopoverWrapper
