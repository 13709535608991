import { FC, useEffect } from 'react'
import './index.css'

import analyticEvents from '@app/analytics/events'
import { useGlobalContext } from '@app/application/Global/GlobalContext'
import { QueryVoccab, URLParamsFormatter } from '@app/infrastructure/url/QueryFormatter'
import { ResponsiveImage } from '@shared/components'
import LinkButton from '@shared/components/LinkButton/LinkButton'
import CurrencyFormatter from '@shared/helpers/currencyFormatter'
import { CAR, CATALOG } from '@shared/helpers/routes'

type TPopularCars = {
  isPrior?: boolean
  renderLimit?: number
}

const PopularCars: FC<TPopularCars> = ({ isPrior = false, renderLimit }) => {
  const fetchPriority = isPrior ? 'high' : 'low'
  const loadingPriority = isPrior ? 'eager' : 'lazy'

  const { getPopularVehicles, popularVehicles } = useGlobalContext()

  useEffect(() => {
    getPopularVehicles()
  }, [])

  const vehiclesToRender = renderLimit ? popularVehicles.slice(0, renderLimit) : popularVehicles

  return (
    <>
      <div className="cards-container">
        {vehiclesToRender?.map((car) => {
          return (
            <div
              className="card"
              key={car.id}
            >
              <div className="responsive-image-container">
                <ResponsiveImage
                  fetchPriority={fetchPriority}
                  loading={loadingPriority}
                  urls={car.src}
                  alt={`Interior view of ${car.model}`}
                />
              </div>
              <div className="popular-details">
                <h5>
                  {car.maker} {car.model} {car.productionYear ?? null}
                </h5>
                <div className="price-info">
                  <p className="label-bold-l">
                    from&nbsp;
                    {CurrencyFormatter.format({ amount: car.price })}
                    &#47;day
                  </p>
                  <p className="price-excluded label-xs">(excluded tax and fees)</p>
                  <p className="price-excluded label-xs">
                    security deposit from&nbsp;
                    {CurrencyFormatter.format({ amount: car.securityDeposit })}
                  </p>
                </div>
              </div>
              <div className="card-buttons">
                <LinkButton
                  analyticId={analyticEvents.homeViewMore}
                  to={URLParamsFormatter.formatPathParams(CAR(car.id), {
                    [QueryVoccab.pickUpLocationName]: car.location.name,
                    [QueryVoccab.rentDays]: car.minRentDays
                  })}
                  name="Details"
                />
                <LinkButton
                  analyticId={analyticEvents.homeRentNow}
                  to={URLParamsFormatter.formatPathParams(CAR(car.id), {
                    [QueryVoccab.pickUpLocationName]: car.location.name,
                    [QueryVoccab.rentDays]: car.minRentDays
                  })}
                  name="Rent now"
                  type="primary"
                />
              </div>
            </div>
          )
        })}
        {popularVehicles?.data?.length === 0 && (
          <p className="no-cars large">No cars available right now</p>
        )}
      </div>
      <div className="show-all-button-cnt">
        <LinkButton
          className="popular-button"
          to={CATALOG()}
          name="Show all"
        />
      </div>
    </>
  )
}

export default PopularCars
