import { CSSProperties, FC, ReactNode } from 'react'

type BoxProps = {
  children: ReactNode
  gap?: string | number
  alignItems?: string
  justifyContent?: string
  style?: CSSProperties
}

export const Box: FC<BoxProps> = ({
  children,
  gap,
  alignItems = 'center',
  justifyContent = 'space-between',
  style
}) => {
  return (
    <div
      style={{
        display: 'flex',
        gap,
        alignItems,
        justifyContent,
        ...style
      }}
    >
      {children}
    </div>
  )
}
