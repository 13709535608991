import { TEnvVar } from '@api/v1'
import Cookies, { CookiesVoccab } from '@app/infrastructure/storage/cookies'
import { LocalStorage, LocalStorageVoccab } from '@app/infrastructure/storage/localStorage'

import { TFbAnalyticsInstance } from './fb-pixel'
import { TGAnalytics } from './google'

type TEventMeta = {
  id?: string
  name?: string
  to?: string
  href?: string
  selectedFilters?: string
  page?: string
  ga?: string
  fbc?: string
  item_id?: string
}

type TAnalyticsConstructor = {
  gtmId: TEnvVar
  fbId: TEnvVar
  baseUrl: string
}

class AnalyticsInit {
  private gtmId: TEnvVar
  private fbId: TEnvVar
  private baseUrl: string
  private analyticModulesInstances: {
    IS_MODULES_LOAD_SUCCESS: boolean
    GA: TGAnalytics
    INTERNAL: {
      configure: (args: any) => void
      track: (event: string, metaData: TEventMeta) => void
      start: () => void
    }
    FB_PIXEL: TFbAnalyticsInstance
  }

  constructor({ gtmId, baseUrl, fbId }: TAnalyticsConstructor) {
    this.gtmId = gtmId
    this.baseUrl = baseUrl
    this.fbId = fbId

    if (!this.gtmId) {
      console.error('Initialization failed: GTM ID is not provided.')
    }

    this.analyticModulesInstances = {
      IS_MODULES_LOAD_SUCCESS: false,
      GA: {
        init: () => {},
        sendEvent: () => {}
      },
      INTERNAL: {
        configure: () => {},
        track: () => {},
        start: () => {}
      },
      FB_PIXEL: {
        init: () => {},
        pageView: () => {}
      }
    }
  }

  private async getInstance() {
    if (this.getIsAccepted() && !this.analyticModulesInstances.IS_MODULES_LOAD_SUCCESS) {
      try {
        const fbPixel = (await import('./fb-pixel')).default
        const googleAnalytics = (await import('./google')).default

        const ahoy = (await import('ahoy.js')).default
        // @INFO: startOnReady must be disabled by default to prevent unexpected behavior
        ahoy.configure({
          startOnReady: false
        })

        this.analyticModulesInstances = {
          IS_MODULES_LOAD_SUCCESS: true,
          GA: googleAnalytics,
          FB_PIXEL: fbPixel,
          INTERNAL: ahoy
        }
      } catch (error) {
        console.error('Initialization failed:', error)
      }
    }

    return this.analyticModulesInstances
  }

  public async init() {
    if (!this.gtmId) {
      console.error('Initialization skipped: GTM ID is not defined.')
      return
    }
    if (!this.fbId) {
      console.error('Initialization skipped: Fb ID is not defined.')
      return
    }
    const initializeInstances = await this.getInstance()

    initializeInstances.GA.init(this.gtmId)

    initializeInstances.FB_PIXEL.init(this.fbId)

    initializeInstances.INTERNAL.configure({
      urlPrefix: this.baseUrl,
      visitsUrl: '/ahoy/visits',
      eventsUrl: '/ahoy/events',
      page: null,
      platform: 'Web',
      useBeacon: true,
      trackVisits: true,
      cookies: true,
      cookieDomain: null,
      headers: {},
      visitParams: {},
      withCredentials: false,
      visitDuration: 4 * 60, // 4 hours
      visitorDuration: 1 * 365 * 24 * 60 // 1 year
    })
    initializeInstances.INTERNAL.start()
  }

  public async send(event: string, metaData: TEventMeta) {
    const analyticsInstances = await this.getInstance()
    analyticsInstances.GA.sendEvent(event, metaData)

    metaData.ga = Cookies.get(CookiesVoccab.googleAnalyticsId)
    metaData.fbc = Cookies.get(CookiesVoccab.facebookPixelId)

    analyticsInstances.INTERNAL.track(event, metaData)
  }

  public async setAcceptTerms() {
    LocalStorage.save(LocalStorageVoccab.isAnalyticsAllowed, true)
    await this.init()
  }

  public getIsAccepted() {
    return Boolean(LocalStorage.load(LocalStorageVoccab.isAnalyticsAllowed))
  }
}

const Analytics: AnalyticsInit = new AnalyticsInit({
  gtmId: import.meta.env.VITE_GTM_CODE as TEnvVar,
  fbId: import.meta.env.VITE_FB_ID as TEnvVar,
  baseUrl: import.meta.env.VITE_API_V1_URL
})

export default Analytics
