export const throttle = (fn, wait) => {
  let isThrottled = false
  let savedArgs
  let savedContext

  const later = () => {
    if (savedArgs) {
      fn.apply(savedContext, savedArgs)
      savedArgs = null
      savedContext = null
      setTimeout(later, wait)
    } else {
      isThrottled = false
    }
  }

  return function (...args) {
    if (isThrottled) {
      savedArgs = args
      savedContext = this
      return
    }

    fn.apply(this, args)
    isThrottled = true
    setTimeout(later, wait)
  }
}

export const pluralFormatter = new Intl.PluralRules('en')

export const timestamp = () => Math.floor(Date.now() / 1000)

export const debounce = (fn, delay) => {
  let timer

  return function (...args) {
    clearTimeout(timer)

    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

export const getCountryCode = () => {
  // const isProduction = import.meta.env.VITE_ENV === 'production'
  // const shouldUseUkraineCode = Boolean(LocalStorage.load(LocalStorageVoccab.isExtended))

  // if (isProduction) {
  //   return shouldUseUkraineCode ? '+38' : '+1'
  // }
  return '+1'
}
