import './index.css'

import cn from 'classnames'
import { ChangeEvent, FC, FocusEvent, JSX, MouseEvent, useRef, useState } from 'react'

import { debounce } from '@shared/helpers/helpers'
import { Dismiss } from '@shared/icons'

const DEFAULT_INPUT_PLACEHOLDER = 'Select...'

/* @DOC: LIST EXAMPLES
  grouped: 
    options={[
    {
      label: 'Group 1',
      options: [
        {
          label: 'Group 1, option 1',
          value: 'value_1'
        },
        {
          label: 'Group 1, option 2',
          value: 'value_2'
        }
      ]
    },
    {
      label: 'Group 2',
      options: [
        {
          label: 'Group 2, option 1',
          value: 'value_3'
        },
        {
          label: 'Group 2, option 2',
          value: 'value_4'
        }
      ]
    }
  ]}

  single: 
    options={[
        {
          label: 'Product A',
          value: 'Laptop'
        },
        {
          label: 'Product B',
          value: 'Smartphone'
        },
        {
          label: 'Product C',
          value: 'Tablet'
        },
        {
          label: 'Product D',
          value: 'Smartwatch'
        }
      ]}
   
*/

export type TSelectOption<T> = {
  label: string
  value: T | Record<string, T>
}

type TGroupedOption = {
  label: string
  options: Array<TSelectOption<unknown>>
}

type TOption = TSelectOption<unknown> | TGroupedOption

export type TSelectProps = {
  options: TOption[] | null
  searchValue: string | null
  label?: string
  defaultValue?: TSelectOption<unknown> | null
  formatOption?: (val: TSelectOption<unknown>) => JSX.Element
  placeholder?: string
  onSelect: (val: TSelectOption<unknown>) => void
  onChange: (val: string | null) => void | Promise<void>
  isInverse?: boolean
}

const Select: FC<TSelectProps> = ({
  defaultValue = null,
  label,
  options,
  placeholder = DEFAULT_INPUT_PLACEHOLDER,
  formatOption,
  onSelect,
  onChange,
  isInverse
}) => {
  const [selectedValue, setSelectedValue] = useState<TSelectOption<unknown> | null>(defaultValue)
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const inputRef = useRef(null)
  const searchValueRef = useRef<string>(defaultValue?.label ?? '')

  const handleInputClick = (event: MouseEvent<HTMLButtonElement | HTMLInputElement>) => {
    const target = event.target as HTMLElement

    if (target.tagName !== 'INPUT' && inputRef?.current) {
      inputRef.current.focus()
    }

    setIsOpen((prev) => !prev)
  }

  const handleSelect = (option: TSelectOption<unknown>) => {
    if (searchValue) setSearchValue('')
    setSelectedValue(option)
    onSelect(option)
    setIsOpen(false)
    searchValueRef.current = `${option?.value?.name}, ${option.label}`
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value ?? null
    setIsOpen(true)
    setSelectedValue(null)
    setSearchValue(searchTerm)
    void onChange(searchTerm)
  }

  const handleClear = (event: MouseEvent<HTMLSpanElement> | FocusEvent<HTMLInputElement>) => {
    event.stopPropagation()
    if (inputRef?.current) inputRef.current.value = ''
    setSearchValue('')
    setSelectedValue(null)
    searchValueRef.current = ''
    void onChange(null)
  }

  const handleBlur = debounce((event: FocusEvent<HTMLInputElement>) => {
    const isClickInsideListItem = event.relatedTarget?.closest('li')

    if (isClickInsideListItem) return

    if (!searchValueRef.current) {
      setIsOpen(false)
      setSearchValue('')
      if (inputRef?.current) inputRef.current.value = ''
      searchValueRef.current = ''
      void onChange(null)
    } else {
      setIsOpen(false)
      setSearchValue('')
    }
  }, 280)

  const getInputValue = () => {
    if (searchValue) return searchValue
    if (selectedValue?.value?.name && selectedValue?.label) {
      return `${selectedValue.value.name}, ${selectedValue.label}`
    }
    return selectedValue?.label ?? ''
  }

  const renderListItem = () => {
    if (!options?.length) {
      if (searchValue.length < 2) return <li className="location-item">Type to start searching</li>
      if (searchValue.length > 2 && !options?.length) {
        return <li className="location-item">Nothing found</li>
      }
    }

    return options?.map((item, index) => {
      if (item.options) {
        return (
          <li key={index}>
            <p className="select-group-title medium">{item.label}</p>
            <ul
              className="select-group-list"
              role="listbox"
            >
              {item.options.map((option, subIndex) => (
                <li
                  key={subIndex}
                  aria-label={`Select ${item.label}`}
                  aria-selected={false}
                  className="select-location-item"
                  onClick={() => handleSelect(option)}
                  role="option"
                  tabIndex={-1}
                >
                  {formatOption ? formatOption(option) : <p>{option.label}</p>}
                </li>
              ))}
            </ul>
          </li>
        )
      } else {
        return (
          <li
            key={index}
            aria-label={`Select ${item.label}`}
            aria-selected={false}
            className="location-item"
            onClick={() => handleSelect(item)}
            role="option"
            tabIndex={-1}
          >
            <p className="suggestion-location-name">{item.value.name}</p>
            <p className="suggestion-location-label tiny">{item.label}</p>
          </li>
        )
      }
    })
  }

  return (
    <>
      <div className="web-select-wrapper">
        <span
          className={cn('search-label label-s', {
            '--inversed': isInverse
          })}
        >
          {label}&nbsp;location
        </span>
        <div
          className={cn('web-select-trigger', {
            '--inversed': isInverse
          })}
        >
          <input
            ref={inputRef}
            type="text"
            disabled={!onChange}
            placeholder={placeholder}
            className="web-select-input label-l"
            onChange={handleInputChange}
            onClick={handleInputClick}
            value={getInputValue()}
            onBlur={handleBlur}
          />
          <div className="web-select-actions">
            {(searchValue || selectedValue?.label) && (
              <button
                className="clear-icon"
                onClick={handleClear}
              >
                <Dismiss color={isInverse ? 'white' : '#7f7f7f'} />
              </button>
            )}
            <button
              className={cn('triangle-icon-btn', {
                '--inversed': isInverse
              })}
              data-value={isOpen}
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <span
                className={cn('triangle-arrow', {
                  'is-open': isOpen,
                  '--inversed': isInverse
                })}
              />
            </button>
          </div>
        </div>
        {isOpen && (
          <ul
            className="locations-list label-l"
            role="listbox"
          >
            {renderListItem()}
          </ul>
        )}
      </div>
    </>
  )
}

export { Select }
