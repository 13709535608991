import {
  GUEST_LOCATION_ID,
  GUEST_LOCATION_TYPE,
  TLocationSuggestion,
  TLocationSuggestions,
  TSuggestionsList
} from '@api/locations'
import { TLocation, TLocationsResp } from '@api/types'
import { TFeature } from '@api/types/mapBox'

export type TLocations = {
  locations: TLocationsResp
  searchLocations: TLocationSuggestions
  value: string
}

export type TFormattedLocation = {
  lon: number
  lat: number
  type: TLocation['type']
  id: number
}

export type TFormattedLocations = {
  label: string
  value: TFormattedLocation | null
}

const formatSearchLocation = (coordinates: TLocationSuggestion['coordinates']): TFormattedLocation | null => {
  if (!coordinates) return null

  return {
    id: GUEST_LOCATION_ID,
    type: GUEST_LOCATION_TYPE,
    ...coordinates
  }
}

const formatSearchLocations = (
  searchLocations: TLocations['searchLocations']
): TFormattedLocations[] | null => {
  if (!searchLocations?.length) return null

  return searchLocations.map((option) => ({
    label: option.fullAddress,
    value: formatSearchLocation(option.coordinates)
  }))
}

const formatRetrieveLocation = (retrievedLocation: TFeature): TFormattedLocations | null => {
  if (!retrievedLocation) return null

  return {
    label: `${retrievedLocation.properties.name}, ${retrievedLocation.properties.place_formatted}`,
    value: formatSearchLocation({
      lat: retrievedLocation.properties.coordinates.latitude,
      lon: retrievedLocation.properties.coordinates.longitude
    })
  }
}

const formatSuggestionOptions = (options: TSuggestionsList) => {
  if (!options) return null

  return options.map((option) => ({
    label: option.fullAddress,
    value: {
      mapboxId: option.mapboxId,
      name: option.name
    }
  }))
}

const formatLocations = (locations: TLocation[]) => {
  if (!locations?.length) return []

  const [fixedLocation, ...restLocations] = locations.map((item) => ({
    label: item.name,
    value: {
      id: item.id,
      type: item.type,
      price: item.price
    }
  }))

  return [
    {
      label: 'AT HOST LOCATION',
      options: [fixedLocation]
    },
    {
      label: 'LOCATION',
      options: restLocations
    }
  ]
}

export {
  formatSearchLocations,
  formatLocations,
  formatSearchLocation,
  formatSuggestionOptions,
  formatRetrieveLocation
}
