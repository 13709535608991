import './index.css'

import { FC } from 'react'

import analyticEvents from '@app/analytics/events'
import CurrencyFormatter from '@shared/helpers/currencyFormatter'
import { DRIVER_STEP_URL, EXTRAS_STEP_URL, PROTECTION_PLAN_STEP_URL } from '@shared/helpers/routes'

import { Button } from '../Button/Button'

type StickyButtonProps = {
  price: number | null
  onButtonClick: () => void
  onDetailsClick?: () => void
  disabled?: boolean
  isLoading?: boolean
}

export const StickyButton: FC<StickyButtonProps> = ({
  price,
  onButtonClick,
  onDetailsClick,
  disabled = false,
  isLoading = false
}) => {
  const IS_DRIVER_STEP = location.pathname.includes(DRIVER_STEP_URL as string)
  const IS_PROTECTION_PLAN_STEP = location.pathname.includes(PROTECTION_PLAN_STEP_URL as string)
  const IS_EXTRAS_STEP = location.pathname.includes(EXTRAS_STEP_URL as string)

  let buttonProps
  if (IS_PROTECTION_PLAN_STEP) {
    buttonProps = {
      name: 'Continue',
      analyticId: analyticEvents.protectionDone
    }
  } else if (IS_EXTRAS_STEP) {
    buttonProps = {
      name: 'Continue',
      analyticId: analyticEvents.extrasDone
    }
  } else if (IS_DRIVER_STEP) {
    buttonProps = {
      name: 'Submit',
      analyticId: analyticEvents.driverLicenseDone
    }
  } else {
    buttonProps = {
      name: 'Rent now',
      analyticId: analyticEvents.beginOrder
    }
  }

  return (
    <div className="price-cnt-fixed">
      <div className="price-cnt-fixed-content">
        <>
          <h5 className="styled-price">
            {price || price === 0
              ? CurrencyFormatter.format({
                  amount: price
                })
              : 'Calculating...'}
          </h5>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <span
            className="price-details label-bold-l"
            onClick={onDetailsClick}
            role="button"
            tabIndex={0}
            aria-label="Show price details"
          >
            Price details
          </span>
        </>
      </div>
      <Button
        {...buttonProps}
        className="sticky-btn"
        onClick={onButtonClick}
        disabled={disabled}
        isLoading={isLoading}
      />
    </div>
  )
}
