/* eslint-disable @typescript-eslint/prefer-includes */
export type TImages = string[]

export type TImageSized = {
  small: string
  medium: string
  large: string
}

export const getImage = (urls: string[]): TImageSized => {
  const sources: TImageSized = {
    small: '',
    medium: '',
    large: ''
  }

  for (const url of urls) {
    if (url.indexOf('small') !== -1) {
      sources.small = url
    } else if (url.indexOf('medium') !== -1) {
      sources.medium = url
    } else if (url.indexOf('large') !== -1) {
      sources.large = url
    }
  }

  return sources
}
