import { captureException } from '@sentry/react'
import { ErrorInfo, FC, useEffect } from 'react'
import { useNavigate, useRouteError } from 'react-router-dom'

import { Button } from '@shared/components'
import { HOME } from '@shared/helpers/routes'

// @TODO: implement proper styling for this page
type TBrokenApp = {
  resetError: () => void
  errorMessage: unknown
  componentStack?: ErrorInfo['componentStack']
}

const BrokenApp: FC<TBrokenApp> = ({ resetError, errorMessage, componentStack }) => {
  const error = useRouteError() as Error
  const navigate = useNavigate()

  useEffect(() => {
    captureException(error)
  }, [error])

  return (
    <div>
      <h1>Ouch!</h1>
      <Button
        name="Go back home"
        variant="secondary"
        onClick={() => {
          navigate(HOME)
          resetError()
        }}
      />
      {/* {componentStack}
      {errorMessage.toString()} */}
    </div>
  )
}

export { BrokenApp }
