import './index.css'

import { FC } from 'react'

type RentalDateLocationProps = {
  pickupLocation: string
  returnLocation: string
  pickupDate: string
  returnDate: string
}
export const RentalDateLocation: FC<RentalDateLocationProps> = ({
  pickupLocation,
  returnLocation,
  pickupDate,
  returnDate
}) => {
  return (
    <article className="rental-details-cnt">
      <div className="rental-details-inner">
        <section className="rental-details-item">
          <img
            className="rental-details-icon"
            src="/images/booking/calendar.webp"
            alt="Pickup date calendar"
          />
          <div>
            <h6 className="rental-icon-label">Pickup Date</h6>
            <p className="label-m">{pickupDate}</p>
          </div>
        </section>

        <section className="rental-details-item">
          <img
            className="rental-details-icon"
            src="/images/booking/calendar.webp"
            alt="Return date calendar"
          />
          <section>
            <h6 className="rental-icon-label">Return Date</h6>
            <p className="label-m">{returnDate}</p>
          </section>
        </section>
      </div>

      <div className="rental-details-inner">
        <section className="rental-details-item">
          <img
            className="rental-details-icon"
            src="/images/booking/location.webp"
            alt="Pickup location"
          />
          <div>
            <h6 className="rental-icon-label">Pickup Location</h6>
            <p className="label-m">{pickupLocation}</p>
          </div>
        </section>

        <section className="rental-details-item">
          <img
            className="rental-details-icon"
            src="/images/booking/location.webp"
            alt="Return location"
          />
          <div>
            <h6 className="rental-icon-label">Return Location</h6>
            <p className="label-m">{returnLocation}</p>
          </div>
        </section>
      </div>
    </article>
  )
}
