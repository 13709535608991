import './index.css'

import cn from 'classnames'
import { ChangeEvent, forwardRef } from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'

import { getCountryCode } from '@shared/helpers/helpers'

type FormInputProps<T extends Record<string, any>> = {
  id: string
  label: string
  placeholder?: string
  type?: string
  value?: string
  register: UseFormRegister<T>
  error?: FieldError
  readOnly?: boolean
  note?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  theme?: 'light' | 'dark'
}

const formatPhoneNumber = (input: string): string => {
  const digitsOnly = input.replace(/\D/g, '').slice(0, 10)

  if (digitsOnly.length <= 3) return digitsOnly
  if (digitsOnly.length <= 6) return `${digitsOnly.slice(0, 3)} ${digitsOnly.slice(3)}`
  return `${digitsOnly.slice(0, 3)} ${digitsOnly.slice(3, 6)} ${digitsOnly.slice(6)}`
}

export const FormInput = forwardRef<HTMLInputElement, FormInputProps<any>>(
  (
    {
      id,
      label,
      placeholder,
      value = '',
      register,
      error,
      onChange,
      readOnly = false,
      type = 'text',
      note = '',
      theme = 'dark'
    },
    ref
  ) => {
    const isPhoneInput = type === 'tel' || id === 'phone'
    const { ref: registerRef, ...restRegister } = register(id)

    const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
      const formattedNumber = formatPhoneNumber(e.target.value)

      if (onChange) onChange({ ...e, target: { ...e.target, name: id, value: formattedNumber } })
    }

    const commonProps = {
      id,
      placeholder: isPhoneInput ? 'XXX XXX XXXX' : placeholder,
      type: 'text',
      className: cn('form-input', {
        disabled: readOnly,
        '--light-bg': theme === 'light',
        '--red-placeholder': placeholder === 'Required',
        '--with-error': error,
        '--phone-input': isPhoneInput,
        '--with-padding': getCountryCode().length > 2
      }),
      readOnly,
      ...restRegister,
      onChange: isPhoneInput ? handlePhoneNumberChange : onChange,
      value: isPhoneInput ? formatPhoneNumber(value) : value
    }

    return (
      <div className="form-input-container">
        <label
          htmlFor={id}
          className="form-label label-bold-l"
        >
          {label}&nbsp;{note && <p className="tiny">({note})</p>}
        </label>
        {isPhoneInput ? (
          <div className="phone-input-wrapper">
            <span
              className={cn('country-code label-m', {
                disabled: readOnly,
                '--red-placeholder': placeholder === 'Required'
              })}
            >
              {getCountryCode()}
            </span>
            <input
              {...commonProps}
              ref={ref}
              maxLength={14}
            />
          </div>
        ) : (
          <input {...commonProps} />
        )}
        {error && <p className="error-message tiny">{error.message}</p>}
      </div>
    )
  }
)

FormInput.displayName = 'FormInput'
