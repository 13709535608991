import { createContext, FC, ReactNode, useContext, useState } from 'react'

import { TAllowedMethods, TFetchResult } from '@api/api'
import { TFiltersResp, TPopularVehiclesResp } from '@api/types'
import { TSelectedVehicle, TSelectedVehicleResp } from '@api/types/vehicles'
import { apiV1 } from '@api/v1'
import { API_FILTERS, API_VEHICLES, API_VEHICLES_POPULAR } from '@shared/helpers/routes'

type TGlobalContextType = {
  getPopularVehicles: () => TPopularVehiclesResp
  popularVehicles: TPopularVehiclesResp
  getPotentialVehicle: (id: number) => TSelectedVehicle | null
  getVehicleById: (id: number, callback?: (value: TSelectedVehicle) => void) => Promise<void>
  getFilters: () => Promise<TFiltersResp>
  filterFields: TFiltersResp
}

type GlobalProviderProps = {
  children: ReactNode
}

export const GlobalContext = createContext<TGlobalContextType | null>(null)

export const GlobalProvider: FC<GlobalProviderProps> = ({ children }) => {
  const [vehiclesList, setVehiclesList] = useState<TSelectedVehicle[]>([])
  const [vehiclesIds, setVehiclesIds] = useState<number[]>([])
  const [popularVehicles, setPopularVehicles] = useState<TPopularVehiclesResp>([])
  const [filterFields, setFilters] = useState<TFiltersResp>([])

  const getPotentialVehicle = (id: number): TSelectedVehicle | null => {
    if (!id) return null
    const vehicle = vehiclesList.find((car) => car.details.id === Number(id))
    return vehicle ?? null
  }

  const getVehicleById = async (
    carId: number,
    callback: (value: TSelectedVehicle) => void = () => {}
  ) => {
    if (carId && !vehiclesIds.includes(carId)) {
      try {
        const result: TFetchResult<TSelectedVehicleResp> =
          await apiV1.requestHandler<TSelectedVehicleResp>(
            `${API_VEHICLES}/${carId}`,
            TAllowedMethods.GET
          )
        if (!result.error && result.data?.vehicle) {
          callback(result.data.vehicle)
          setVehiclesList((prevList) => {
            const isVehicleAlreadyInList = prevList.some((car) => Number(car.details.id) === carId)
            return isVehicleAlreadyInList ? prevList : [...prevList, result.data.vehicle]
          })
          setVehiclesIds((prevIds) => {
            return prevIds.includes(carId) ? prevIds : [...prevIds, carId]
          })
        }
      } catch (err) {
        console.error('Error fetching vehicle:', err)
      }
    }
  }

  const getPopularVehicles = async () => {
    if (popularVehicles.length) return popularVehicles
    try {
      const result: TFetchResult<TPopularVehiclesResp> =
        await apiV1.requestHandler_NEXT<TPopularVehiclesResp>({
          path: API_VEHICLES_POPULAR,
          method: TAllowedMethods.GET
        })
      if (!result.error && result.data) {
        setPopularVehicles(result.data)
        return result.data
      }
    } catch (err) {
      console.error('Error fetching popular:', err)
    }
  }

  const getFilters = async () => {
    if (filterFields.length) return filterFields
    try {
      const result: TFetchResult<TFiltersResp> = await apiV1.requestHandler_NEXT<TFiltersResp>({
        path: API_FILTERS,
        method: TAllowedMethods.GET
      })
      if (!result.error && result.data) {
        setFilters(result.data)
        return result.data
      }
      return []
    } catch (err) {
      console.error('Error fetching popular:', err)
      return []
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        getPopularVehicles,
        popularVehicles,
        getPotentialVehicle,
        getVehicleById,
        getFilters,
        filterFields
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(GlobalContext)

  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalProvider')
  }

  return context
}
