import './index.css'

import cn from 'classnames'
import { FC, MouseEvent, ReactNode } from 'react'

import Analytics from '@app/analytics/analytics'
import analyticEvents from '@app/analytics/events'
import { Search } from '@shared/icons'

type TButtonProps = {
  name: string
  analyticId?: string
  analyticParams?: Record<string, string>
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  className?: string
  showIcon?: boolean
  disabled?: boolean
  isLoading?: boolean
  size?: 'medium' | 'large'
  variant?: 'primary' | 'secondary' | 'linkSecondary' | 'containedPrimary' // primary -> black, secondary -> white, linkSecondary -> gray
  children?: ReactNode
  type?: 'submit' | 'button'
  style?: Record<string, string>
}

export const Button: FC<TButtonProps> = ({
  name,
  onClick,
  className,
  analyticId,
  analyticParams = {},
  showIcon = false,
  disabled = false,
  variant = 'primary',
  size = 'medium',
  isLoading = false,
  type = 'button',
  children,
  style
}) => {
  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    void Analytics.send(analyticEvents.triggers.click, {
      name,
      id: analyticId ?? '',
      ...analyticParams
    })

    onClick(e)
  }

  return (
    <button
      className={cn(
        'btn',
        {
          '-primary': variant === 'primary',
          '-secondary': variant === 'secondary',
          '-linkSecondary': variant === 'linkSecondary',
          '-containedPrimary': variant === 'containedPrimary',
          '-medium': size === 'medium',
          '-large': size === 'large'
        },
        className
      )}
      type={type}
      onClick={!disabled ? handleOnClick : undefined}
      disabled={isLoading || disabled}
      style={style}
    >
      <span
        className={cn({
          loader: isLoading
        })}
      />
      <span className="btn-content">
        {showIcon && <Search />}
        {name}
        {children}
      </span>
    </button>
  )
}
