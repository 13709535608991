import './index.css'

import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'

import { FC, ReactNode, useEffect, useMemo, useState } from 'react'

import { LocationsApi } from '@api/locations'
import { TLocation, TLocationsResp } from '@api/types'
import analyticEvents from '@app/analytics/events'
import BookingService from '@app/application/Booking/Booking'
import { TLocationOption, useBookingStore } from '@app/application/Booking/BookingStore'
import { Checkbox, Modal } from '@shared/components'
import CurrencyFormatterBuilder from '@shared/helpers/currencyFormatter'

import { Button } from '../Button/Button'
import { TDatepickerDate } from '../DateTimePicker/Datepicker'
import { DateTimePicker, TDateTimePickerTypes } from '../DateTimePicker/DateTimePicker'
import { Select, TSelectOption, TSelectProps } from '../Select/Select'

import PickupReturnInfo from './PickupReturnInfo'

export type TBooking = {
  id?: number | null
  analytics?: {
    searchButtonId?: string
    searchButtonParams?: Record<string, string>
  }
  onSearch: () => void
  className?: string
  isSearchDisabled?: boolean
  isInverse?: boolean
  forceContainerView?: boolean
  searchButtonName?: string
  defaultLocations?: TLocationsResp
  footer?: ReactNode
  deliveryError?: string
}

export type TLocationType = 'pick' | 'drop'

const BookingContainer: FC<TBooking> = ({
  id = null,
  analytics,
  className,
  onSearch,
  searchButtonName,
  defaultLocations,
  deliveryError,
  isInverse,
  isSearchDisabled = false,
  forceContainerView = false,
  footer = null
}) => {
  const [forceUrlUpdate, setForceUrlUpdate] = useState(false)

  const defaultData = useMemo(
    () => ({
      predefinedLocations: BookingService.getDefaultLocations(defaultLocations),
      selectedLocations: BookingService.getDefaultLocationsValues()
    }),
    [id, defaultLocations?.pickUpLocations?.length, forceUrlUpdate]
  )
  const isSyncWithUrl = !id

  const bookingStore = useBookingStore(
    useShallow((state) => ({
      bookingData: state.bookingData,
      date: state.date,
      storeLocations: state.storeLocations,
      saveSelectedLocations: state.saveSelectedLocations,
      setStoreDate: state.setDate,
      selectedItemId: state.selectedItemId,
      setSelectedItemId: state.setSelectedItemId
    }))
  )

  // @INFO: sync booking store and session store with default locations list if passed
  useEffect(() => {
    if (!id) {
      bookingStore.setSelectedItemId(null)
      bookingStore.saveSelectedLocations({
        id: null,
        locations: defaultData.selectedLocations,
        isSyncPersistent: false
      })
    } else if (id && id !== bookingStore.selectedItemId && defaultLocations) {
      const selectedDefaultLocations = BookingService.getDefaultLocationsValues(
        id,
        defaultLocations
      )
      bookingStore.setSelectedItemId(id)
      bookingStore.saveSelectedLocations({
        id,
        locations: selectedDefaultLocations,
        isSyncPersistent: false
      })
    }
  }, [id, defaultLocations?.pickUpLocations])

  const [date, setDate] = useState<TDatepickerDate>({
    from: bookingStore.date.from,
    to: bookingStore.date.to
  })
  const [suggestedLocations, setSuggestedLocations] = useState<TLocationOption | null>(null)
  const [isDifferentReturnLocation, setDiffLocation] = useState<boolean>(
    BookingService.getParamsData().isDifferentReturnLocation
  )
  const [isOpen, setIsOpen] = useState(false)

  const handleDateAction = (selectedDateTimeRange: TDatepickerDate | null) => {
    if (selectedDateTimeRange !== null) {
      return setDate(selectedDateTimeRange)
    }
    bookingStore.setStoreDate(date)
    BookingService.setSelectedDates(date)
  }

  const handleLocationSelect = async (selectedOption: TLocationOption, type: TLocationType) => {
    let preparedData

    if (selectedOption.value.mapboxId) {
      const data = await LocationsApi.getSelectedLocationInfo(selectedOption.value.mapboxId)
      preparedData = data
    } else {
      preparedData = selectedOption
    }

    const locationsToStore = { ...bookingStore.storeLocations }
    if (type === 'pick') {
      locationsToStore.pickUp = preparedData
      if (!isDifferentReturnLocation) {
        locationsToStore.dropOff = preparedData
      }
    } else if (type === 'drop') {
      locationsToStore.dropOff = preparedData
    }

    if (isSyncWithUrl) {
      setForceUrlUpdate((prev) => !prev)
      BookingService.setSelectedLocation(locationsToStore)
      return
    }
    bookingStore.saveSelectedLocations({
      id,
      locations: locationsToStore
    })
  }

  const handleLocationChange = async (searchValue: TSelectProps['searchValue']) => {
    if (!searchValue) {
      setSuggestedLocations(null)
      return
    }

    const result = isSyncWithUrl
      ? await BookingService.getSearchLocationsDetailed(searchValue)
      : await BookingService.getSearchLocations(searchValue)

    setSuggestedLocations(result)
  }

  const handleToggleDiffReturn = () => {
    const newValue = !isDifferentReturnLocation
    setDiffLocation(newValue)
    BookingService.setToggleDifferentLocation(newValue)

    if (newValue) return
    const locationsToStore = {
      ...bookingStore.storeLocations,
      dropOff: bookingStore.storeLocations.pickUp
    }

    if (isSyncWithUrl && locationsToStore.pickUp) {
      setForceUrlUpdate((prev) => !prev)
      BookingService.setSelectedLocation(locationsToStore)
      return
    }
    bookingStore.saveSelectedLocations({
      id,
      locations: locationsToStore
    })
  }

  const onSearchClick = () => {
    BookingService.setSelectedDates(date)
    onSearch()
    setIsOpen(false)
  }

  const containerView = forceContainerView || isOpen

  const LabelView = (option: TSelectOption<TLocation>) => (
    <>
      <p>{option.label}</p>
      <p>
        {option.value.price > 0
          ? `${CurrencyFormatterBuilder.format({
              amount: option.value.price
            })}`
          : 'Free'}
      </p>
    </>
  )
  const renderBookingDetails = (
    <section
      className={cn('booking-cnt', {
        '--nested': containerView,
        '--inversed': isInverse
      })}
    >
      {!containerView && (
        <PickupReturnInfo
          className="pickup-return-cnt"
          onClick={() => setIsOpen(true)}
          pickUpDate={bookingStore.date.from}
          returnDate={bookingStore.date?.to}
          pickupLocation={bookingStore.storeLocations.pickUp?.label ?? null}
        />
      )}
      <div className={cn('wrapper-select-pickup', className)}>
        <div className="search-wrapper">
          <div className="search-locations">
            <Select
              isInverse={isInverse}
              label={!isDifferentReturnLocation ? 'Pick-up&Return' : 'Pick-up'}
              placeholder="City, airport, address or hotel"
              defaultValue={
                id ? bookingStore.storeLocations.pickUp : defaultData.selectedLocations.pickUp
              }
              options={suggestedLocations ?? defaultData.predefinedLocations?.pickUp}
              formatOption={LabelView}
              onSelect={(val: TLocationOption) => handleLocationSelect(val, 'pick')}
              onChange={handleLocationChange}
            />
            <div className="search-different-location --mobile">
              <Checkbox
                checked={isDifferentReturnLocation}
                onChange={handleToggleDiffReturn}
                label="Different return location"
                appearance={isInverse ? 'dark' : 'light'}
              />
            </div>
            {isDifferentReturnLocation && (
              <Select
                isInverse={isInverse}
                label="Return location"
                placeholder="City, airport, address or hotel"
                defaultValue={
                  id ? bookingStore.storeLocations.dropOff : defaultData.selectedLocations.dropOff
                }
                options={suggestedLocations ?? defaultData.predefinedLocations?.dropOff}
                formatOption={LabelView}
                onSelect={(val: TLocationOption) => handleLocationSelect(val, 'drop')}
                onChange={handleLocationChange}
              />
            )}
            {deliveryError && <p className="delivery-error label-s">{deliveryError}</p>}
          </div>
          <div className="search-datepickers">
            <DateTimePicker
              type={TDateTimePickerTypes.START}
              date={date}
              onDateAction={handleDateAction}
              isInverse={isInverse}
            />
            <DateTimePicker
              type={TDateTimePickerTypes.END}
              date={date}
              onDateAction={handleDateAction}
              isInverse={isInverse}
            />
          </div>
          <div
            className={cn('search-button', {
              '--inversed': isInverse
            })}
          >
            <Button
              analyticId={analytics?.searchButtonId ?? analyticEvents.search}
              analyticParams={analytics?.searchButtonParams}
              size="large"
              disabled={isSearchDisabled}
              name={searchButtonName ?? 'Search'}
              onClick={onSearchClick}
              showIcon={!searchButtonName}
            />
          </div>
        </div>
        <div className="search-different-location --desktop">
          <Checkbox
            checked={isDifferentReturnLocation}
            onChange={handleToggleDiffReturn}
            label="Different return location"
            appearance={isInverse ? 'dark' : 'light'}
          />
        </div>
        {footer}
      </div>
    </section>
  )

  return isOpen ? (
    <Modal open={isOpen}>
      <div className="select-location">{renderBookingDetails}</div>
    </Modal>
  ) : (
    renderBookingDetails
  )
}

export { BookingContainer }
