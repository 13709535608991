export enum CookiesVoccab {
  'googleAnalyticsId' = '_ga',
  'facebookPixelId' = '_fbp',
  'visitorId' = 'ahoy_visitor',
  'visitId' = 'ahoy_visit'
}

class CookieInit {
  public get(key: CookiesVoccab): string | undefined {
    const cookies = this.parseCookies()
    return cookies[key]
  }

  private parseCookies(): Record<string, string> {
    const cookieString = document.cookie
    const cookies: Record<string, string> = {}

    if (!cookieString) {
      return cookies
    }

    for (const cookie of cookieString.split(';')) {
      const [key, ...value] = cookie.split('=')
      const trimmedKey = key.trim()
      const trimmedValue = value.join('=').trim()
      if (trimmedKey) {
        cookies[trimmedKey] = decodeURIComponent(trimmedValue)
      }
    }

    return cookies
  }
}

const Cookies = new CookieInit()

export default Cookies
