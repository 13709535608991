import './index.css'

import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import analyticEvents from '@app/analytics/events'
import { useAuthStore } from '@app/application/Authorization/AuthStore'
import { QueryVoccab, URLParamsFormatter } from '@app/infrastructure/url/QueryFormatter'
import LinkButton from '@shared/components/LinkButton/LinkButton'
import { AVAILABLE_NOW_MIN_RENT_DAYS } from '@shared/helpers/constants'
import { dayjs } from '@shared/helpers/dayjsExtended'
import { CATALOG, HOME, PROFILE_TRIPS, SIGN_IN } from '@shared/helpers/routes'
import { Logo, Profile } from '@shared/icons'
import { Menu } from '../Menu/Menu'

export const Header = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const navigate = useNavigate()
  const token = useAuthStore((state) => state.token)

  const handleToggleMenu = () => setOpenMenu(!openMenu)

  const onProfileClick = () => {
    navigate(token ? PROFILE_TRIPS : SIGN_IN)
  }

  return (
    <header className="header-wrapper">
      <div className="header-logo">
        <Link to={HOME}>
          <Logo />
        </Link>
      </div>
      <nav className="header-navigation">
        <div className="header-navigation-buttons">
          <LinkButton
            analyticId={analyticEvents.carAvailableNow}
            name="Cars available now"
            to={URLParamsFormatter.formatPathParams(CATALOG(), {
              [QueryVoccab.dropOffTime]: dayjs
                .utc()
                .add(AVAILABLE_NOW_MIN_RENT_DAYS, 'days')
                .format()
            })}
          />
        </div>
        <div className="profile-icon">
          <Profile onClick={onProfileClick} />
        </div>
        <Menu
          openMenu={openMenu}
          handleToggleMenu={handleToggleMenu}
        />
      </nav>
    </header>
  )
}
