import dayjs, { Dayjs as TDayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { TIMESTAMPS, TTimestampValue } from './constants'

export const HOUR_PATTERN = 'h:mm A'
export const DATES_PATTERN = 'MM/DD/YYYY'

const dayjsExtended = dayjs.extend(utc)

const formattedDateTime = (dateTime: TDayjs) => dateTime.format(`${DATES_PATTERN} hh:mm A`)

const getLocalTimeParam = (param?: string | TDayjs): TDayjs =>
  param ? dayjs(param).utc().local() : dayjsExtended.utc().local()

export const getDiffInHrs = (to: Date | null, from?: Date) =>
  dayjs(to).diff(dayjs(from), 'hours', true)

export const getDiffInDays = (to: Date, from: Date) => dayjs(to).diff(dayjs(from), 'day')

export const getTimestamp = (date?: TDayjs | null): string => {
  if (!date) return dayjsExtended.utc().local().startOf('hour').format(HOUR_PATTERN)

  if (!TIMESTAMPS.has(date.format(HOUR_PATTERN))) {
    return date.startOf('hour').format(HOUR_PATTERN)
  } else {
    return date.format(HOUR_PATTERN)
  }
}

export const setHours = (date: TDayjs | null, time: string): TDayjs | null => {
  const timeValue: TTimestampValue = TIMESTAMPS.get(time)

  if (date) return date.set('hour', timeValue.hour).set('minute', timeValue.minutes)

  return null
}

export default dayjsExtended
export { dayjs, formattedDateTime, getLocalTimeParam, TDayjs }
