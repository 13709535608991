import cn from 'classnames'
import { Dayjs as TDayjs } from 'dayjs'
import { FC } from 'react'

import { formattedDateTime } from '@shared/helpers/dayjsExtended'
import { EditIcon } from '@shared/icons'

type PickupReturnInfoProps = {
  className?: string
  onClick?: () => void
  pickUpDate: TDayjs | null
  returnDate: TDayjs | null
  pickupLocation: string | null
}

const PickupReturnInfo: FC<PickupReturnInfoProps> = ({
  className,
  onClick,
  pickUpDate,
  returnDate,
  pickupLocation
}) => {
  return (
    <button
      className={cn(className, 'pickup-summary')}
      onClick={onClick}
    >
      <p className="pickup-location label-l">{pickupLocation ?? 'Select pickup location'}</p>
      <p className="pickup-date label-m">
        {pickUpDate ? formattedDateTime(pickUpDate) : 'Select pickup date'}
        &nbsp;&ndash;&nbsp;
        {returnDate ? formattedDateTime(returnDate) : 'Select return date'}
        {onClick && <EditIcon />}
      </p>
    </button>
  )
}

export default PickupReturnInfo
