import './index.css'

import { useState } from 'react'
import { Link } from 'react-router-dom'

import Analytics from '@app/analytics/analytics'
import { Button } from '@shared/components'

const CookieModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(!Analytics.getIsAccepted())
  const [isMoreInfoOpen, setMoreInfo] = useState<boolean>(false)

  const handleAccept = () => {
    void Analytics.setAcceptTerms()
    setIsOpen(false)
  }

  return (
    isOpen && (
      <section className="cookie-modal-cnt">
        {isMoreInfoOpen ? (
          <>
            <h6 className="cookie-modal-title">
              Cookies on our website serve the following purposes:
            </h6>
            <p className="label-l">
              Essential Navigation: Enable seamless browsing and efficient use of our services.
              These cookies are necessary and cannot be disabled.
            </p>
            <ul className="cookie-list">
              <li className="label-l">
                Audience Analysis: Help us understand and analyze visitor activity on our site.
              </li>
              <li className="label-l">
                Performance Optimization: Enhance website efficiency by monitoring and improving
                performance.
              </li>
              <li className="label-l">
                Ad Personalization: Measure, refine, and tailor advertisements to your preferences.
              </li>
            </ul>
            <p className="label-l">
              Some cookies are placed by our trusted partners. You can manage your cookie
              preferences and learn more about our practices anytime by visiting the&nbsp;
              <Link
                to="/legal/cookie"
                className="cookie-modal-link"
              >
                Cookies
              </Link>
              &nbsp;page via the website footer.
            </p>
          </>
        ) : (
          <>
            <h6 className="cookie-modal-title">Cookies & Privacy</h6>
            <p className="label-l">
              This website uses cookies to ensure you get the best experience on our website.
            </p>
          </>
        )}
        <div className="cookie-modal-buttons">
          {isMoreInfoOpen ? (
            <Button
              name="Reject All"
              onClick={() => setIsOpen(false)}
              variant="linkSecondary"
            />
          ) : (
            <Button
              name="More Information"
              onClick={() => setMoreInfo(true)}
              variant="linkSecondary"
            />
          )}
          <Button
            name="Accept All"
            onClick={handleAccept}
            variant="secondary"
          />
        </div>
      </section>
    )
  )
}

export default CookieModal
