import { FC } from 'react'

import { EmptyStar, FullFilledStar, HalfFilledStar } from '@shared/icons'

type StarsRatinProps = {
  rating: number
}
export const StarsRating: FC<StarsRatinProps> = ({ rating }) => {
  const stars = Array.from(
    {
      length: 5
    },
    (_, index) => {
      if (rating >= index + 1) {
        return <FullFilledStar />
      } else if (rating > index && rating < index + 1) {
        return <HalfFilledStar />
      } else {
        return <EmptyStar />
      }
    }
  )

  return (
    <div>
      {stars.map((star, index) => (
        <span key={index}>{star}</span>
      ))}
    </div>
  )
}
