import { FC, ReactNode } from 'react'

import Analytics from '@app/analytics/analytics'
import analyticEvents from '@app/analytics/events'

type TOutboundLink = {
  analyticId: string
  href: string
  children: ReactNode
}

const ExternalLink: FC<TOutboundLink> = ({ analyticId, href, children }) => {
  const handleOnClick = () => {
    void Analytics.send(analyticEvents.triggers.externalLink, {
      id: analyticId,
      href
    })
  }

  return (
    <a
      href={href}
      onClick={handleOnClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

export default ExternalLink
