import { FC, CSSProperties } from 'react'

type AvatarProps = {
  src: string
  alt: string
  size?: number
}

export const Avatar: FC<AvatarProps> = ({ src, alt, size = 80 }) => {
  const containerStyles: CSSProperties = {
    display: 'inline-block',
    width: size,
    height: size,
    overflow: 'hidden',
    borderRadius: '50%'
  }

  const imageStyles: CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }

  return (
    <div style={containerStyles}>
      <img
        src={src ?? '/images/defaultAvatar.webp'}
        alt={alt}
        style={imageStyles}
      />
    </div>
  )
}
