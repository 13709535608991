const lazyRoute =
  <T = any>(importFn: () => Promise<T>, componentName: keyof T) =>
  async () => {
    const module = await importFn()
    return {
      Component: module[componentName]
    }
  }

export default lazyRoute
