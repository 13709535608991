import { captureMessage } from '@sentry/react'
import queryString from 'query-string'

import {
  formatRetrieveLocation,
  TFormattedLocations
} from '@app/application/Booking/formatLocations'
import { LocalStorage, LocalStorageVoccab } from '@app/infrastructure/storage/localStorage'

import { TFeature, TServiceSuggestionResp } from './types/mapBox'
import { mapBoxApiV1, TAllowedMethods, TEnvVar } from './v1'

export const GUEST_LOCATION_ID = 4
export const GUEST_LOCATION_TYPE = 'guest'

export type LocationData = {
  pickUpLocation: string
  returnLocation?: string | null
}
export type TLocationSuggestion = {
  coordinates: {
    lon: number
    lat: number
  }
  fullAddress: string
}
export type TLocationSuggestions = TLocationSuggestion[]
export type TSuggestionItem = {
  mapboxId: string
  fullAddress: string
  name: string
}
export type TSuggestionsList = TSuggestionItem[] | null
type TSearchParams = {
  q: string
  types: string
}

class Locations {
  private token: TEnvVar
  private mapboxSessionTokenKey: LocalStorageVoccab

  constructor(token: TEnvVar) {
    this.token = token
    this.mapboxSessionTokenKey = LocalStorageVoccab.mapboxSessionToken
    this.initializeMapboxSessionToken()
  }

  private initializeMapboxSessionToken() {
    if (!LocalStorage.exists(this.mapboxSessionTokenKey)) {
      this.startNewMapboxSession()
    }
  }

  private getMapboxSessionToken(): string | null {
    return LocalStorage.load<string>(this.mapboxSessionTokenKey)
  }

  private setMapboxSessionToken(value: string) {
    LocalStorage.save(this.mapboxSessionTokenKey, value)
  }

  private startNewMapboxSession() {
    this.setMapboxSessionToken(crypto.randomUUID())
  }

  // https://docs.mapbox.com/api/search/search-box/#retrieve-a-suggested-feature
  private async retrieveLocation(mapboxId: string): Promise<TFormattedLocations | null> {
    try {
      const result = await mapBoxApiV1.requestHandler<{ features: TFeature[] }>(
        `/retrieve/${mapboxId}?language=en&session_token=${this.getMapboxSessionToken()}&access_token=${this.token}`,
        TAllowedMethods.GET
      )
      if (!result.data?.features) {
        this.startNewMapboxSession()
        captureMessage('Error retrieving location', 'error')
        return null
      }

      const retrievedLocation = result.data.features[0]
      this.startNewMapboxSession()

      return formatRetrieveLocation(retrievedLocation)
    } catch {
      captureMessage('Error retrieving location', 'error')
      return null
    }
  }

  // https://docs.mapbox.com/api/search/search-box/#get-suggested-results
  private async fetchSuggestions(query: TSearchParams): Promise<TSuggestionsList | null> {
    try {
      const params = {
        country: 'us',
        poi_category: 'airport,hotel',
        ...query
      }

      const formattedParams = queryString.stringify(params)

      const result = await mapBoxApiV1.requestHandler<{ suggestions: TServiceSuggestionResp[] }>(
        `/suggest?${formattedParams}&session_token=${this.getMapboxSessionToken()}&access_token=${this.token}`,
        TAllowedMethods.GET
      )

      if (!result.data) {
        this.startNewMapboxSession()
        captureMessage('Error fetching suggestions', 'error')
        return null
      }

      const suggestions: TSuggestionsList = result.data.suggestions.map((suggestion) => ({
        mapboxId: suggestion.mapbox_id,
        fullAddress: suggestion.place_formatted,
        name: suggestion.name_preferred ?? suggestion.name
      }))

      return suggestions?.length ? suggestions : null
    } catch {
      captureMessage('Error fetching suggestions', 'error')
      return null
    }
  }

  public fetchSuggestionList(queryParams: TSearchParams) {
    return this.fetchSuggestions(queryParams)
  }

  public getSelectedLocationInfo(id: string) {
    return this.retrieveLocation(id)
  }
}

const LocationsApi = new Locations(import.meta.env.VITE_MAPBOX_ACCESS_TOKEN as TEnvVar)

export { LocationsApi }
