import './index.css'

import cn from 'classnames'
import { Link, useLocation } from 'react-router-dom'

import analyticEvents from '@app/analytics/events'
import {
  ABOUT_US,
  CONTACT_US,
  HOME,
  HOME_FAQ_ANCHOR,
  HOME_FLEET_ANCHOR,
  PROFILE
} from '@shared/helpers/routes'
import { FaceBook, Instagram, Logo, TikTok, Triller, X } from '@shared/icons'

import ExternalLink from '../ExternalLink/ExternalLink'

const FOOTER_LINKS = [
  {
    href: ABOUT_US,
    label: 'About us'
  },
  {
    href: CONTACT_US,
    label: 'Contact us'
  },
  {
    href: HOME_FLEET_ANCHOR,
    label: 'Fleet'
  },
  {
    href: HOME_FAQ_ANCHOR,
    label: 'FAQ'
  }
]
const SOCIAL_LINKS = [
  {
    id: analyticEvents.footerInstagram,
    href: 'https://www.instagram.com/whipss_rental/',
    icon: <Instagram />
  },
  {
    id: analyticEvents.footerFacebook,
    href: 'https://www.facebook.com/whipssrental/',
    icon: <FaceBook />
  },
  {
    id: analyticEvents.footerTiktok,
    href: 'https://www.tiktok.com/@yourwhipss',
    icon: <TikTok />
  },
  {
    id: analyticEvents.footerX,
    href: 'https://x.com/Whipss_Rental',
    icon: <X />
  },
  {
    id: analyticEvents.footerTriller,
    href: 'https://triller.co/@whipss_rental',
    icon: <Triller />
  }
]
const LEGAL_LINKS = [
  {
    to: '/legal/privacy',
    label: 'Privacy'
  },
  {
    to: '/legal/terms',
    label: 'Terms'
  },
  {
    to: '/legal/prohibited/',
    label: 'Prohibited Uses'
  },
  {
    to: '/legal/rental',
    label: 'Rental Agreement'
  },
  {
    to: '/legal/cookie',
    label: 'Cookies Policy'
  }
]

export const Footer = () => {
  const location = useLocation()

  return (
    <footer
      className={cn('footer-wrapper', {
        '--hidden': location.pathname.includes(PROFILE())
      })}
    >
      <div className="footer-content">
        <div className="footer-logo">
          <Link
            to={HOME}
            className="footer-logo-img"
          >
            <Logo />
          </Link>
        </div>
        <div className="footer-nav">
          <div className="navigation-wrapper">
            <div className="navigation-title label-bold-m">Car rental in Miami</div>
            <ul>
              {FOOTER_LINKS.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.href}
                    className="link"
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="social-content">
          <div className="social-wrapper">
            {SOCIAL_LINKS.map((link) => (
              <ExternalLink
                key={link.id}
                analyticId={link.id}
                href={link.href}
              >
                {link.icon}
              </ExternalLink>
            ))}
          </div>
          <a
            className="footer-phone label-bold-m"
            href="tel:+1(954)637-1257"
          >
            +1(954)637-1257
          </a>
        </div>
        <div className="copyright-wrapper">
          <div className="copyright-cnt">
            <span className="copyright label-s">©2025 Whipss</span>
            {LEGAL_LINKS.map(({ to, label }) => (
              <Link
                key={to}
                className="styled-link label-s"
                to={to}
              >
                {label}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}
