import { LocationsApi, TSuggestionsList } from '@api/locations'
import { LocalStorageVoccab, SessionStorage } from '@app/infrastructure/storage/localStorage'
import { ParamsFormatter, TParseValue } from '@app/infrastructure/url/QueryFormatter'
import { TDatepickerDate } from '@shared/components/DateTimePicker/Datepicker'
import { TBooking } from '@shared/components/SelectLocation/SelectPickupAndReturn'

import { BookingParamsVoccab, BookingQuery } from '../../infrastructure/url/BokingQuery'

import { TLocationOption } from './BookingStore'
import {
  formatLocations,
  formatSearchLocation,
  formatSuggestionOptions,
  TLocations
} from './formatLocations'

export type TStoredLocations = {
  pickUp: TLocationOption | null
  dropOff: TLocationOption | null
}

const bookingQueryConstructor = new BookingQuery(new ParamsFormatter())

class BookingService {
  public getLocations(params: Partial<Record<BookingParamsVoccab, TParseValue>>) {
    const searchLocations = bookingQueryConstructor.getLocations(params)
    return {
      pickUp: formatSearchLocation(searchLocations.pickUp),
      dropOff: formatSearchLocation(searchLocations.dropOff)
    }
  }

  public getParamsData() {
    return bookingQueryConstructor.getParams()
  }

  public setSelectedDates(selectedDateTimeRange: TDatepickerDate) {
    bookingQueryConstructor.setDate(selectedDateTimeRange)
  }

  public setToggleDifferentLocation(isDifferentReturn: boolean) {
    bookingQueryConstructor.setIsDifferentReturnLocation(isDifferentReturn)
  }

  public setSelectedLocation(selectedLocation: TStoredLocations) {
    bookingQueryConstructor.setLocation(selectedLocation)
  }

  public async getSearchLocationsDetailed(search: string) {
    const data: TSuggestionsList = await LocationsApi.fetchSuggestionList({
      q: search,
      types: 'address,poi,city'
    })

    return formatSuggestionOptions(data)
  }

  public async getSearchLocations(search: string) {
    const data: TSuggestionsList = await LocationsApi.fetchSuggestionList({
      q: search,
      types: 'address,poi'
    })

    return formatSuggestionOptions(data)
  }

  public getDefaultLocationsValues(
    id?: number | null,
    locations?: TBooking['defaultLocations']
  ): TStoredLocations {
    if (locations && id) {
      const storedLocations = this.getStoredLocationsItems(id)

      if (storedLocations.pickUp) {
        return storedLocations
      }

      const defaultPickupLocation = locations.pickUpLocations[0] // first location is always default by API
      const defaultDropOffLocation = locations.returnLocations[0]

      return {
        pickUp: {
          label: defaultPickupLocation.name,
          value: {
            id: defaultPickupLocation.id,
            type: defaultPickupLocation.type
          }
        },
        dropOff: {
          label: defaultDropOffLocation.name,
          value: {
            id: defaultDropOffLocation.id,
            type: defaultDropOffLocation.type
          }
        }
      }
    }

    const bookingData = this.getParamsData()

    const pickUp = bookingData.locations.pickUp
      ? {
          label: bookingData.locations.pickUp.name,
          value: {
            lon: bookingData.locations.pickUp.lon,
            lat: bookingData.locations.pickUp.lat
          }
        }
      : null

    const dropOff = bookingData.locations.dropOff
      ? {
          label: bookingData.locations.dropOff.name,
          value: {
            lon: bookingData.locations.dropOff.lon,
            lat: bookingData.locations.dropOff.lat
          }
        }
      : null

    return {
      pickUp,
      dropOff
    }
  }

  public getDefaultLocations(locations: TLocations['locations']) {
    if (!locations) return null

    return {
      pickUp: formatLocations(locations.pickUpLocations),
      dropOff: formatLocations(locations.returnLocations)
    }
  }

  public getStoredLocationsItems(id: number | null): TStoredLocations {
    if (!id) {
      return {
        pickUp: null,
        dropOff: null
      }
    }

    const storedLocations: Map<number, TStoredLocations> | null =
      SessionStorage.load<TStoredLocations>(LocalStorageVoccab.booking)

    if (!storedLocations) {
      return {
        pickUp: null,
        dropOff: null
      }
    }

    const MapStoredLocations = new Map(storedLocations)

    return {
      pickUp: MapStoredLocations.get(id)?.pickUp ?? null,
      dropOff: MapStoredLocations.get(id)?.dropOff ?? null
    }
  }

  public setStoreLocationItems(id: number | null, locations: TStoredLocations) {
    if (!id) return

    const storedLocations = SessionStorage.load<Map<number, TStoredLocations>>(
      LocalStorageVoccab.booking
    )

    if (!storedLocations) {
      SessionStorage.save(LocalStorageVoccab.booking, [[id, locations]])
    } else {
      const MapStoredLocations = new Map(storedLocations)
      MapStoredLocations.set(id, locations)
      SessionStorage.save(LocalStorageVoccab.booking, [...MapStoredLocations])
    }
  }
}

const bookingConstructor = new BookingService()

export default bookingConstructor
