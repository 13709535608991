import './index.css'

import cn from 'classnames'
import { FC, MouseEvent, useMemo, useState } from 'react'

import { Button, Modal } from '@shared/components'
import { AVAILABLE_NOW_MIN_RENT_DAYS, TIMESTAMPS } from '@shared/helpers/constants'
import { DATES_PATTERN, dayjs, getTimestamp, setHours, TDayjs } from '@shared/helpers/dayjsExtended'

import { Datepicker, TDatepickerDate } from './Datepicker'
import PopoverWrapper from './PopoverWrapper'

export enum TDateTimePickerActions {
  SAVE = 'save',
  CANCEL = 'cancel'
}

export enum TDateTimePickerTypes {
  START = 'start',
  END = 'end'
}

type DatepickerProps = {
  type: TDateTimePickerTypes
  date: TDatepickerDate
  onDateAction: (selectedDate: TDatepickerDate) => void
  isInverse?: boolean
}
export const DateTimePicker: FC<DatepickerProps> = ({ date, onDateAction, type, isInverse }) => {
  const time = useMemo(
    () => getTimestamp(type === TDateTimePickerTypes.START ? date.from : date.to),
    [date.from, date.to]
  )

  const [isDatepickerOpen, setIsDatepickerOpen] = useState<boolean>(false)
  const [timeAnchorEl, setTimeAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [dateAnchorEl, setDateAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [originalTime] = useState<string>(time)

  const onMobileDatepickerClick = () => {
    setIsDatepickerOpen(true)
  }

  const onDesktopDatepickerClick = (event: MouseEvent<HTMLButtonElement>) => {
    setDateAnchorEl(event.currentTarget)
  }

  const onSaveSelectedDates = (action: TDateTimePickerActions) => {
    if (action === TDateTimePickerActions.SAVE) {
      onDateAction(null)
    } else {
      onDateAction({
        from: setHours(dayjs(), originalTime),
        to: setHours(dayjs().add(AVAILABLE_NOW_MIN_RENT_DAYS, 'day'), originalTime)
      })
    }
    setIsDatepickerOpen(false)
    if (dateAnchorEl) {
      setDateAnchorEl(null)
    }
  }

  const onCancelTimepicker = () => {
    onDateAction({
      from: type === TDateTimePickerTypes.START ? setHours(date.from, originalTime) : date.from,
      to: type === TDateTimePickerTypes.END ? setHours(date.to, originalTime) : date.to
    })

    setTimeAnchorEl(null)
  }

  const onSaveTimepicker = () => {
    onDateAction(null)
    setTimeAnchorEl(null)
  }

  const handleTimeChange = (selectedTime: string) => {
    onDateAction({
      from: type === TDateTimePickerTypes.START ? setHours(date.from, selectedTime) : date.from,
      to: type === TDateTimePickerTypes.END ? setHours(date.to, selectedTime) : date.to
    })
  }

  const handleDateSelect = (day: TDayjs) => {
    const updatedHour = dayjs().add(2, 'hour').hour()

    let selectedRange = date
    if (!date.from || (date.from && date.to)) {
      selectedRange = {
        from: day,
        to: null
      }
    } else {
      if (day.isBefore(date.from)) {
        selectedRange = {
          from: day.hour(updatedHour),
          to: date.from.hour(updatedHour)
        }
      } else {
        selectedRange = {
          from: date.from.hour(updatedHour),
          to: day.hour(updatedHour)
        }
      }
    }
    onDateAction(selectedRange)
  }

  const endDate = date.to ? date.to.format(DATES_PATTERN) : 'Return date'

  return (
    <div>
      <span className={cn('search-label label-s', { '--inversed': isInverse })}>
        {type === TDateTimePickerTypes.START ? 'Pick-up' : 'Return'} date and time
      </span>
      <div className="datepicker-wrapper">
        <button
          className="datepicker-trigger --mobile"
          onClick={onMobileDatepickerClick}
        >
          <span className={cn('datepicker-date label-l', { '--inversed': isInverse })}>
            {type === TDateTimePickerTypes.START ? date.from.format(DATES_PATTERN) : endDate}
          </span>
          <span
            className={cn('triangle-arrow', {
              'is-open': dateAnchorEl,
              '--inversed': isInverse
            })}
          />
        </button>
        <button
          className="datepicker-trigger --desktop"
          onClick={onDesktopDatepickerClick}
        >
          <span className="datepicker-date label-l">
            {type === TDateTimePickerTypes.START ? date.from.format(DATES_PATTERN) : endDate}
          </span>
          <span
            className={cn('triangle-arrow', {
              'is-open': dateAnchorEl,
              '--inversed': isInverse
            })}
          />
        </button>
        <Modal open={isDatepickerOpen}>
          <div className="datepicker-modal">
            <Datepicker
              date={date}
              onSaveDatepicker={() => onSaveSelectedDates(TDateTimePickerActions.SAVE)}
              onCancelDatepicker={() => onSaveSelectedDates(TDateTimePickerActions.CANCEL)}
              handleDateSelect={handleDateSelect}
            />
          </div>
        </Modal>
        <PopoverWrapper
          type={type}
          anchorEl={dateAnchorEl}
          onClose={() => onSaveSelectedDates(TDateTimePickerActions.CANCEL)}
        >
          <Datepicker
            date={date}
            onSaveDatepicker={() => onSaveSelectedDates(TDateTimePickerActions.SAVE)}
            onCancelDatepicker={() => onSaveSelectedDates(TDateTimePickerActions.CANCEL)}
            visibleAmountOfMonths={2}
            handleDateSelect={handleDateSelect}
          />
        </PopoverWrapper>
        <button
          className="datepicker-trigger time"
          onClick={(event: MouseEvent<HTMLButtonElement>) => setTimeAnchorEl(event.currentTarget)}
        >
          <span className={cn('datepicker-date label-l', { '--inversed': isInverse })}>{time}</span>
          <span
            className={cn('triangle-arrow', {
              'is-open': timeAnchorEl,
              '--inversed': isInverse
            })}
          />
        </button>

        <PopoverWrapper
          type={type}
          anchorEl={timeAnchorEl}
          onClose={onCancelTimepicker}
        >
          <div className="time-dropdown">
            {Array.from(TIMESTAMPS.keys()).map((timestamp: string) => (
              <button
                key={timestamp}
                className={cn('time-button label-m', { selected: time === timestamp })}
                onClick={() => handleTimeChange(timestamp)}
              >
                {timestamp}
              </button>
            ))}
          </div>
          <div className="datepicker-footer">
            <hr />
            <div className="date-range-buttons">
              <Button
                onClick={onCancelTimepicker}
                name="Cancel"
                variant="secondary"
              />
              <Button
                onClick={onSaveTimepicker}
                name="Save"
              />
            </div>
          </div>
        </PopoverWrapper>
      </div>
    </div>
  )
}
