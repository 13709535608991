export enum LocalStorageVoccab {
  'isAnalyticsAllowed' = 'a',
  'booking' = 'b',
  'mapboxSessionToken' = 't',
  'accessToken' = 'at',
  'accessTokenExpiration' = 'ate',
  'isExtended' = 'isExtended'
}

class StorageManager {
  private static instances = new Map<Storage, StorageManager>()
  private storage: Storage

  private constructor(storage: Storage) {
    this.storage = storage
  }

  static getInstance(storage: Storage): StorageManager {
    if (!StorageManager.instances.has(storage)) {
      StorageManager.instances.set(storage, new StorageManager(storage))
    }

    return StorageManager.instances.get(storage)
  }

  save<T>(key: LocalStorageVoccab, value: T): void {
    try {
      const serializedValue = JSON.stringify(value)
      this.storage.setItem(key, serializedValue)
    } catch (error) {
      console.error('Error saving to localStorage:', error)
    }
  }

  load<T>(key: LocalStorageVoccab): T | null {
    try {
      const serializedValue = this.storage.getItem(key)
      return serializedValue ? (JSON.parse(serializedValue) as T) : null
    } catch (error) {
      console.error('Error loading from localStorage:', error)
      return null
    }
  }

  remove(key: string): void {
    try {
      this.storage.removeItem(key)
    } catch (error) {
      console.error('Error removing from localStorage:', error)
    }
  }

  exists(key: string): boolean {
    return this.storage.getItem(key) !== null
  }
}

const LocalStorage = StorageManager.getInstance(localStorage)

const SessionStorage = StorageManager.getInstance(sessionStorage)

export { LocalStorage, SessionStorage }
