import { create } from 'zustand'

import { TSelectOption } from '@shared/components/Select/Select'
import { TBookingParams } from '@app/infrastructure/url/BokingQuery'
import { TDatepickerDate } from '@shared/components/DateTimePicker/Datepicker'

import BookingService, { TStoredLocations } from './Booking'
import { TFormattedLocation } from './formatLocations'

export type TLocationOption = TSelectOption<TFormattedLocation>

type TBookingStore = {
  bookingData: TBookingParams
  date: TDatepickerDate
  setDate: (val: TDatepickerDate) => void
  storeLocations: TStoredLocations
  saveSelectedLocations: (val: {
    id: number | null
    locations: TStoredLocations
    isSyncPersistent?: boolean
  }) => void
  selectedItemId: number | null
  setSelectedItemId: (id: number | null) => void
}

const useBookingStore = create<TBookingStore>((set) => {
  const bookingData = BookingService.getParamsData()

  return {
    bookingData,
    selectedItemId: null,
    storeLocations: BookingService.getDefaultLocationsValues(),
    date: {
      from: bookingData.pickUpTime,
      to: bookingData.dropOffTime
    },
    setSelectedItemId: (id) => {
      set({
        selectedItemId: id
      })
    },
    setDate: (date) =>
      set({
        date
      }),
    saveSelectedLocations: ({ id, locations, isSyncPersistent = true }) => {
      set({
        storeLocations: locations
      })

      if (isSyncPersistent) BookingService.setStoreLocationItems(id, locations)
    }
  }
})

export { useBookingStore }
