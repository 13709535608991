import './index.css'

import cn from 'classnames'
import { ChangeEvent, FC, ReactNode, useState } from 'react'

type TCheckbox = {
  appearance?: 'light' | 'dark'
  checked?: boolean
  onChange?: (checked: boolean) => void
  label?: ReactNode
}

const Checkbox: FC<TCheckbox> = ({ checked = false, onChange, label, appearance = 'light' }) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
    if (onChange) {
      onChange(event.target.checked)
    }
  }

  return (
    <label
      className={cn('checkbox-label', {
        '--dark': appearance === 'dark',
        '--light': appearance === 'light'
      })}
    >
      <input
        type="checkbox"
        className="checkbox"
        checked={isChecked}
        onChange={handleChange}
      />
      <span className="checkbox-text label-m">{label}</span>
    </label>
  )
}

export { Checkbox }
