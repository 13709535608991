import { FC, JSX } from 'react'

type TLoader = {
  loadingState: boolean
  children: JSX.Element | null
}

const Loader: FC<TLoader> = ({ loadingState, children }) => {
  if (loadingState) {
    return (
      <h3
        style={{
          textAlign: 'center'
        }}
      >
        Loading...
      </h3>
    )
  }

  return children
}

export { Loader }
