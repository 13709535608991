import './index.css'

import { FC } from 'react'

import { TQuotaPrice } from '@api/types/quota'
import CurrencyFormatterBuilder from '@shared/helpers/currencyFormatter'

import { Box } from '../../Box/Box'

type TRentalPriceSummaryProps = {
  priceDetails: TQuotaPrice
  securityDeposit: number
}

export const RentalPriceSummary: FC<TRentalPriceSummaryProps> = ({
  priceDetails,
  securityDeposit
}) => {
  return (
    <div className="rental-price-wrapper">
      <ul className="rental-price-list">
        {priceDetails.options.map((option, index) => (
          <li
            className="rental-price-item"
            key={index}
          >
            <p className="medium">{option.name}</p>
            <p className="medium">{option.total}</p>
          </li>
        ))}
      </ul>
      <Box justifyContent="space-between">
        <h5>Total</h5>
        <h5>${priceDetails.total}</h5>
      </Box>
      <Box justifyContent="space-between">
        <p className="medium">Security deposit</p>
        <p className="medium">
          {CurrencyFormatterBuilder.format({
            amount: securityDeposit
          })}
        </p>
      </Box>
    </div>
  )
}
