import './index.css'

import { FC, ReactNode, useEffect, useRef } from 'react'
import cn from 'classnames'

type ModalProps = {
  open: boolean
  children: ReactNode
  onClose?: () => void
  background?: string
  fullScreen?: boolean
}

export const Modal: FC<ModalProps> = ({
  open,
  children,
  onClose,
  background = '#fff',
  fullScreen = false
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    const dialog = dialogRef.current
    if (!dialog) return

    if (open) {
      dialog.showModal()
    } else if (dialog.open) {
      dialog.close()
    }
  }, [open])

  return (
    <dialog
      ref={dialogRef}
      onClose={onClose}
      className={cn('modal-dialog', { '--full-screen': fullScreen })}
      style={{
        background
      }}
    >
      {children}
    </dialog>
  )
}
