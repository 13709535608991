import './index.css'

import cn from 'classnames'
import { FC, MouseEvent, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import Analytics from '@app/analytics/analytics'
import analyticEvents from '@app/analytics/events'

type LinkButtonProps = {
  to: string
  analyticId?: string
  analyticParams?: Record<string, string | number>
  disabled?: boolean
  children?: ReactNode
  className?: string
  name?: string
  type?: 'primary' | 'secondary' // primary -> white, secondary -> gray
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
}

const LinkButton: FC<LinkButtonProps> = ({
  to,
  analyticId,
  analyticParams = {},
  children,
  className,
  name,
  type = 'secondary',
  disabled = false,
  onClick
}) => {
  const handleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    void Analytics.send(analyticEvents.triggers.link, {
      name: name ?? 'no-name',
      id: analyticId ?? '',
      to,
      ...analyticParams
    })

    if (onClick) onClick(e)
  }

  return (
    <Link
      to={to}
      viewTransition
      onClick={handleOnClick}
      className={cn('link-button', className, {
        '--primary': type === 'primary',
        '--secondary': type === 'secondary',
        '--disabled': disabled
      })}
    >
      {name ?? children}
    </Link>
  )
}

export default LinkButton
