const analyticEvents = {
  triggers: {
    click: 'custom-button-click',
    pageview: 'custom-pageview',
    sort: 'custom-sort',
    filter: 'custom-filter-select',
    link: 'custom-link-click',
    externalLink: 'custom-external-link-click'
  },
  beginOrder: 'add_to_cart',
  carAvailableNow: 'car_available_now',
  finishOrder: 'send',
  orderCompleted: 'thank_you',
  search: 'search',
  viewItem: 'view_item',
  homeViewMore: 'main_view_more',
  homeRentNow: 'main_rent_now',
  edit: 'edit',
  protectionDone: 'protection',
  extrasDone: 'extras',
  driverLicenseDone: 'driver_license',
  footerInstagram: 'footer_instagram',
  footerFacebook: 'footer_facebook',
  footerTiktok: 'footer_tiktok',
  footerX: 'footer_x',
  footerTriller: 'footer_triller',
  homePage: 'home_page'
}

export default analyticEvents
