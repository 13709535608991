import './index.css'

import { FC } from 'react'
import { DayPicker, NextMonthButtonProps, PreviousMonthButtonProps } from 'react-day-picker'

import { AVAILABLE_NOW_MIN_RENT_DAYS } from '@shared/helpers/constants'
import { dayjs, getDiffInDays, TDayjs } from '@shared/helpers/dayjsExtended'
import { DatepickerNextMonth, DatepickerPrevMonth } from '@shared/icons'

import { Button } from '../Button/Button'

export type TDatepickerDate = {
  from: TDayjs
  to: TDayjs | null
}

type TDatepicker = {
  date: TDatepickerDate
  onSaveDatepicker: () => void
  onCancelDatepicker: () => void
  visibleAmountOfMonths?: number
  handleDateSelect: (day: TDayjs) => void
}
export const Datepicker: FC<TDatepicker> = ({
  date,
  onSaveDatepicker,
  onCancelDatepicker,
  handleDateSelect,
  visibleAmountOfMonths = 12
}) => {
  const CustomPrevArrow = (props: PreviousMonthButtonProps) => (
    <DatepickerPrevMonth callback={props.onClick} />
  )
  const CustomNextArrow = (props: NextMonthButtonProps) => (
    <DatepickerNextMonth callback={props.onClick} />
  )

  return (
    <div>
      <DayPicker
        className="datepicker"
        mode="single"
        disabled={{
          before: dayjs().toDate()
        }}
        onDayClick={(day) => handleDateSelect(dayjs(day))}
        numberOfMonths={visibleAmountOfMonths}
        components={{
          PreviousMonthButton: (props: PreviousMonthButtonProps) => <CustomPrevArrow {...props} />,
          NextMonthButton: (props: NextMonthButtonProps) => <CustomNextArrow {...props} />
        }}
        modifiers={{
          range_start: date.from.toDate(),
          range_end: date.to?.toDate() ?? null,
          range_middle: (day) => {
            const formatDate = dayjs(day)
            return formatDate > date.from && formatDate < date.to
          }
        }}
      />
      <div className="datepicker-footer">
        <hr />
        <div className="datepicker-footer-cnt">
          {!date.to ? (
            <div className="selected-dates label-l">Select trip timeframe</div>
          ) : (
            <div className="selected-dates label-l">{`${date.from.format('MMM D, YYYY')} - ${date.to.format('MMM D, YYYY')}`}</div>
          )}
          <div className="date-range-buttons">
            <Button
              onClick={onCancelDatepicker}
              name="Cancel"
              variant="secondary"
            />
            <Button
              onClick={onSaveDatepicker}
              name="Save"
              disabled={!date.to || getDiffInDays(date.to, date.from) < AVAILABLE_NOW_MIN_RENT_DAYS}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
