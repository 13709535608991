export const ABOUT_US = '/about-us'
export const LEARN_MORE = '/learn-more'
export const CONTACT_US = '/contact-us'
export const PAYMENT_OPTIONS = '/payment-options'
export const CHECKOUT = '/checkout/:id'
export const BECOME_HOST = '/become-host'

export const CATALOG = (path: string | number = '') => `/catalog${path}`
export const CAR = (path: string | number = '') => `/car/${path}`
export const QUOTA_STEPS = (stepId: string | number = '', carId: string | number = '') =>
  `/car/${carId}/rentals${stepId}`

// HOME
export const HOME = '/'
export const HOME_FAQ_ANCHOR = '/#faq'
export const HOME_FLEET_ANCHOR = '/#fleet'

// Auth
export const SIGN_UP = '/sign-up'
export const SIGN_IN = '/sign-in'

// Quota
// @TODO: to be deleted
export const PROTECTION_PLAN_STEP_URL = '/protection-plan'
export const EXTRAS_STEP_URL = '/extras'
export const DRIVER_STEP_URL = '/driver'
export const CONFIRM_STEP_URL = '/confirmation'

// Profile related
export const PROFILE = (path: string | number = '') => `/profile${path}`
export const TRIP = (path: string | number = '') => PROFILE(`/trip/${path}`)
export const PROFILE_TRIPS = PROFILE('/trips')
export const ACTIVE_TRIPS = 'active'
export const TRIP_HISTORY = 'history'
export const INBOX = 'inbox'
export const EDIT_PROFILE = 'settings'

// legal
export const LEGAL = '/legal'
export const TERMS = 'terms'
export const PRIVACY = 'privacy'
export const PROHIBITED = 'prohibited'
export const RENTAL = 'rental'
export const COOKIE = 'cookie'

// API
// @INFO: Authorized API routes starting from /profile
export const API_PROTECTION_PLANS_URL = '/protection_plans'
export const API_EXTRAS_URL = '/extras'
export const API_VERIFICATION_URL = '/verification'
export const API_VERIFICATION_CODE_URL = '/verification/code'
export const API_QUOTA_URL = '/quota'
export const API_CALCULATE_PRICE_URL = '/calculate_price'
export const API_CONTACT_US_URL = '/contact_us'
export const API_AUTH_VERIFICATION = '/auth/verification'
export const API_AUTH_VERIFICATION_CODE = '/auth/verification/code'
export const API_VEHICLES = '/vehicles'
export const API_FILTERS = '/filters'
export const API_VEHICLES_POPULAR = '/popular_vehicles'
export const API_PROFILE_URL = '/profile'

export const API_TRIP = (path: string) => `/trips${path}` // example: trips/path
export const API_TRIP_PAYMENT_DETAILS = API_TRIP('/payment') // example: trips/payment

//Service pages
export const INTERNAL_SERVER_ERROR = '/500'
export const NOT_FOUND = '/404'

// @TODO to refactor
// export const CAR_IS_BOOKED = '/car-is-booked'
// export const FIND_ANY_INFO = '/find-any-info'

export const CAR_BREADCRUMBS = [
  {
    label: 'home',
    path: HOME
  },
  {
    label: 'fleet',
    path: null
  }
]
