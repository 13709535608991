import cn from 'classnames'
import { FC, ReactNode } from 'react'

import './index.css'

type AccordionProps = {
  summary: ReactNode
  children: ReactNode
  size?: 'small' | 'medium' | 'large'
}

export const Accordion: FC<AccordionProps> = ({ summary, children, size = 'large' }) => (
  <details className={cn('accordion-details', { '--small': size === 'small' })}>
    <summary className={cn('accordion-summary', { '--small': size === 'small' })}>
      {summary}
    </summary>
    <div>{children}</div>
  </details>
)
