import { Link } from 'react-router-dom'
import './index.css'

import analyticEvents from '@app/analytics/events'
import { QueryVoccab, URLParamsFormatter } from '@app/infrastructure/url/QueryFormatter'
import { AVAILABLE_NOW_MIN_RENT_DAYS } from '@shared/helpers/constants'
import dayjsExtended from '@shared/helpers/dayjsExtended'
import {
  ABOUT_US,
  CATALOG,
  CONTACT_US,
  HOME_FAQ_ANCHOR,
  PAYMENT_OPTIONS
} from '@shared/helpers/routes'

import { BurgerMenu, CloseMenu } from '../../icons'
import LinkButton from '../LinkButton/LinkButton'

type TMenu = {
  openMenu: boolean
  handleToggleMenu: () => void
}

const menuItems = [
  {
    path: ABOUT_US,
    label: 'About Us'
  },
  {
    path: PAYMENT_OPTIONS,
    label: 'How to pay'
  },
  {
    path: CONTACT_US,
    label: 'Contact Us'
  },
  /* { path: BECOME_HOST, label: 'Become a Host' },*/ /* commented out before completion */
  {
    path: HOME_FAQ_ANCHOR,
    label: 'FAQ'
  }
]

export const Menu = ({ openMenu, handleToggleMenu }: TMenu) => {
  return (
    <>
      <BurgerMenu
        onClick={handleToggleMenu}
        className="header-menu"
      />
      {openMenu && (
        <div
          className="menu-wrp"
          open={openMenu}
          onClose={handleToggleMenu}
        >
          <CloseMenu
            onClick={handleToggleMenu}
            className="menu-close"
          />
          <ul className="menu-navigation">
            {menuItems.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.path}
                  className="menu-nav-item"
                  onClick={handleToggleMenu}
                >
                  {item.label}
                </Link>
              </li>
            ))}
            <div className="menu-button-cars">
              <LinkButton
                analyticId={analyticEvents.carAvailableNow}
                name="Cars available now"
                onClick={handleToggleMenu}
                to={URLParamsFormatter.formatPathParams(CATALOG(), {
                  [QueryVoccab.dropOffTime]: dayjsExtended
                    .utc()
                    .add(AVAILABLE_NOW_MIN_RENT_DAYS, 'days')
                    .format()
                })}
              />
            </div>
          </ul>
        </div>
      )}
    </>
  )
}
