import queryString from 'query-string'

import AuthorizationService from '@app/application/Authorization/Authorization'
import {
  QuerySuffixVoccab,
  TParseValue,
  URLParamsFormatter
} from '@app/infrastructure/url/QueryFormatter'
import { API_PROFILE_URL, API_QUOTA_URL, INTERNAL_SERVER_ERROR } from '@shared/helpers/routes'
import { sanitizeFields } from '@shared/utils/object'

export type TFetchOptions = RequestInit & {
  signal?: AbortSignal
}

export type TFetchResult<TRequest> = {
  isLoading: boolean
  data: TRequest | null
  error: Error | null
  abort: () => void
}

export enum TAllowedMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

export type TRequestParams = {
  path: string
  method: TAllowedMethods
  options?: TFetchOptions
  body?: unknown
  params?: Record<string, unknown> | null
}

class ApiService {
  private readonly baseUrl: string
  private abortController: AbortController | null = null

  public isLoading = false
  public data: unknown = null
  public error: Error | null = null

  constructor(baseUrl: string) {
    if (!baseUrl) {
      throw new TypeError('baseUrl is required to API requests')
    }
    this.baseUrl = baseUrl
  }

  private async request<TRequest>(
    path: string,
    method: TAllowedMethods,
    options?: TFetchOptions
  ): Promise<TRequest | null> {
    this.abortController = new AbortController()
    const signal = this.abortController.signal

    const url = `${this.baseUrl}${path}`
    const response = await fetch(url, {
      ...options,
      method,
      signal
    })

    this.handleErrors(response, method)

    return this.handleContentType<TRequest>(response, url)
  }

  private handleErrors(response: Response, method: string): void {
    if (response.status === 401) {
      AuthorizationService.signOutAttempt()
      throw new Error('Unauthorized')
    }
    if (response.status >= 400 && response.status < 500) {
      throw new Error(`Client error on ${method}: ${response.status} ${response.statusText}`)
    }
    if (response.status >= 500) {
      window.location.href = INTERNAL_SERVER_ERROR
      throw new Error(`Server error on ${method}: ${response.status} ${response.statusText}`)
    }
  }

  private async handleContentType<TContent>(
    response: Response,
    url: string
  ): Promise<TContent | null> {
    const contentType = response.headers.get('Content-Type')
    if (
      contentType?.includes('application/json') ||
      contentType?.includes('application/vnd.geo+json')
    ) {
      return (await response.json()) as TContent
    } else if (contentType?.includes('text/html')) {
      const htmlContent = await response.text()
      console.error(`Content-Type HTML for response ${url}:`, htmlContent)
      return null
    } else {
      throw new Error('Unsupported content type')
    }
  }

  public async requestHandler<TRequest>(
    path: string,
    method: TAllowedMethods,
    body?: TRequest,
    options?: TFetchOptions
  ): Promise<TFetchResult<TRequest>> {
    this.resetState()

    try {
      const headers = {
        'Content-Type': 'application/json'
      }
      // @INFO: /quota is a special case to add authorization header mentioned in the auth docs - https://github.com/rentality-us/docs/blob/main/auth/README.md
      if (path.includes(API_PROFILE_URL) || path.includes(API_QUOTA_URL)) {
        headers.Authorization = `Bearer ${AuthorizationService.getStoreToken()}`
      }

      this.data = (await this.request<TRequest>(path, method, {
        headers,
        ...options,
        body: body ? JSON.stringify(sanitizeFields(body as Record<string, any>)) : undefined
      })) as TRequest
    } catch (error) {
      this.error = error instanceof Error ? error : new Error('Unknown error occurred')
    } finally {
      this.isLoading = false
    }

    return this.createFetchResult<TRequest>()
  }

  public async requestHandler_NEXT<TResponse>({
    path,
    method,
    body,
    options,
    params = null
  }: TRequestParams): Promise<TFetchResult<TResponse>> {
    this.resetState()
    const headers = {
      'Content-Type': 'application/json'
    }
    if (params) {
      const inlineParams = queryString.stringify(params)
      path = `${path}?${inlineParams}`
    }

    // @INFO: /quota is a special case to add authorization header mentioned in the auth docs - https://github.com/rentality-us/docs/blob/main/auth/README.md
    if (path.includes(API_PROFILE_URL) || path.includes(API_QUOTA_URL)) {
      headers.Authorization = `Bearer ${AuthorizationService.getStoreToken()}`
    }

    try {
      this.data = (await this.request<TResponse>(path, method, {
        headers,
        ...options,
        body: body ? JSON.stringify(sanitizeFields(body as Record<string, unknown>)) : undefined
      })) as TResponse
    } catch (error) {
      this.error = error instanceof Error ? error : new Error('Unknown error occurred')
    } finally {
      this.isLoading = false
    }

    return this.createFetchResult<TResponse>()
  }

  public async batchRequestHandler<TRequest>(
    batchParams: Array<{
      path: string
      method?: TAllowedMethods
      body?: TRequest
      options?: TFetchOptions
    }>
  ): Promise<TFetchResult<TRequest>> {
    this.resetState()

    try {
      this.data = (await Promise.all(
        batchParams.map(({ path, method = TAllowedMethods.GET, body, options }) =>
          this.requestHandler<TRequest>(path, method, body, options)
        )
      )) as TRequest[]
    } catch (error) {
      this.error = error instanceof Error ? error : new Error('Unknown error occurred')
    } finally {
      this.isLoading = false
    }

    return this.createFetchResult<TRequest>()
  }

  private resetState(): void {
    this.isLoading = true
    this.data = null
    this.error = null
  }

  private createFetchResult<TRequest>(): TFetchResult<TRequest> {
    const result = {
      isLoading: this.isLoading,
      data: this.data as TRequest | TRequest[] | null,
      error: this.error,
      abort: () => this.abortRequest()
    }

    this.resetState()
    return result
  }

  public formatToFilters(
    input: Record<string, TParseValue>
  ): Array<{ groupCode: string; selectedValues: TParseValue }> {
    const filters = Object.entries(input).map(([key, value]) => ({
      groupCode: key,
      selectedValues: value,
      type: URLParamsFormatter.getParam(
        URLParamsFormatter.formatDynamicObjectServiceField(key, QuerySuffixVoccab.filterType)
      )
    }))

    return filters
  }

  public abortRequest(): void {
    if (this.abortController) {
      this.abortController.abort()
      this.abortController = null
      console.info('Request aborted')
    } else {
      console.warn('No request to abort')
    }
  }
}

export default ApiService
