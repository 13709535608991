import { ErrorBoundary } from '@sentry/react'
import { useEffect } from 'react'
import { Outlet, ScrollRestoration, useLocation, useParams } from 'react-router-dom'

import Analytics from '@app/analytics/analytics'
import analyticEvents from '@app/analytics/events'
import { Footer, Header } from '@shared/components'
import { CONFIRM_STEP_URL, INTERNAL_SERVER_ERROR, NOT_FOUND } from '@shared/helpers/routes'

import CookieModal from '../CookiePreferences/CookieModal'

import { BrokenApp } from './BrokenApp'

const Layout = () => {
  const { pathname } = useLocation()
  const { id } = useParams()

  useEffect(() => {
    let event: { page: string; id: string; item_id?: string } = {
      page: pathname,
      id: id ?? ''
    }
    if (pathname.includes(CONFIRM_STEP_URL)) {
      event = {
        page: pathname,
        id: analyticEvents.orderCompleted,
        item_id: id
      }
    }

    void Analytics.send(analyticEvents.triggers.pageview, event)
  }, [pathname])

  const excludedPaths = ['/', INTERNAL_SERVER_ERROR, NOT_FOUND ]

  return (
    <>
      <ErrorBoundary
        fallback={({ error, componentStack, resetError }) => (
          <BrokenApp
            resetError={resetError}
            errorMessage={error}
            componentStack={componentStack}
          />
        )}
      >
        <ScrollRestoration />
        {!excludedPaths.includes(pathname) && <Header />}
        <Outlet />
        <Footer />
        <CookieModal />
      </ErrorBoundary>
    </>
  )
}

export default Layout
