import { TStoredLocations } from '@app/application/Booking/Booking'
import { TParseValue } from '@app/infrastructure/url/QueryFormatter'
import { TDatepickerDate } from '@shared/components/DateTimePicker/Datepicker'
import { AVAILABLE_NOW_MIN_RENT_DAYS } from '@shared/helpers/constants'
import { dayjs, getLocalTimeParam, TDayjs } from '@shared/helpers/dayjsExtended'

export enum BookingParamsVoccab {
  // pickup
  'pickUpTime' = 'pt',
  'pickUpLocationName' = 'pn',
  'pickUpLatitude' = 'plat',
  'pickUpLongitude' = 'plon',
  // return
  'dropOffTime' = 'dt',
  'dropOffLocationName' = 'dn',
  'dropOffLatitude' = 'dlat',
  'dropOffLongitude' = 'dlon',
  // other
  'isDifferentReturnLocation' = 'isdrl',
  'rentDays' = 'rd'
}

type TParsedLocation = {
  name: string
  lat: string
  lon: string
} | null

export type TBookingParams = {
  // pickup
  pickUpTime: TDayjs
  // return
  dropOffTime: TDayjs
  locations: {
    pickUp: TParsedLocation
    dropOff: TParsedLocation
  }
  // other
  isDifferentReturnLocation: boolean
}

export type TLocations = Record<'pickUp' | 'dropOff', TParsedLocation>

export class BookingQuery {
  private readonly today: TDayjs = getLocalTimeParam()
  private globalURLParams

  constructor(params) {
    this.globalURLParams = params
  }

  public getLocations(urlParams?: Partial<Record<BookingParamsVoccab, TParseValue>>): TLocations {
    const params = urlParams ?? this.globalURLParams.getParams()

    const pickUp = params[BookingParamsVoccab.pickUpLocationName]
      ? {
          name: params[BookingParamsVoccab.pickUpLocationName],
          lat: params[BookingParamsVoccab.pickUpLatitude],
          lon: params[BookingParamsVoccab.pickUpLongitude]
        }
      : null

    const dropOff = params[BookingParamsVoccab.dropOffLocationName]
      ? {
          name: params[BookingParamsVoccab.dropOffLocationName],
          lat: params[BookingParamsVoccab.dropOffLatitude],
          lon: params[BookingParamsVoccab.dropOffLongitude]
        }
      : null

    return {
      pickUp,
      dropOff
    }
  }

  public setIsDifferentReturnLocation(isDifferentReturn: boolean) {
    this.globalURLParams.Unstable_updateURLParams({
      [BookingParamsVoccab.isDifferentReturnLocation]: isDifferentReturn
    })
  }

  public setDate(dateTimeRange: TDatepickerDate) {
    this.globalURLParams.Unstable_updateURLParams({
      [BookingParamsVoccab.pickUpTime]: dateTimeRange.from.format(),
      [BookingParamsVoccab.dropOffTime]: dateTimeRange.to?.format()
    })
  }

  public setLocation(location: TStoredLocations) {
    if (location.pickUp) {
      this.globalURLParams.Unstable_updateURLParams({
        [BookingParamsVoccab.pickUpLocationName]: location.pickUp.label,
        [BookingParamsVoccab.pickUpLongitude]: location.pickUp.value.lon,
        [BookingParamsVoccab.pickUpLatitude]: location.pickUp.value.lat,
        [BookingParamsVoccab.dropOffLocationName]: location.dropOff.label,
        [BookingParamsVoccab.dropOffLatitude]: location.dropOff.value.lat,
        [BookingParamsVoccab.dropOffLongitude]: location.dropOff.value.lon
      })
      return
    }

    this.globalURLParams.Unstable_updateURLParams({
      [BookingParamsVoccab.dropOffLocationName]: location.dropOff.label,
      [BookingParamsVoccab.dropOffLatitude]: location.dropOff.value.lat,
      [BookingParamsVoccab.dropOffLongitude]: location.dropOff.value.lon
    })
  }

  private getDay() {
    const params = this.globalURLParams.getParams()

    const MIN_RENT_DAYS = Number(
      params[BookingParamsVoccab.rentDays] ?? AVAILABLE_NOW_MIN_RENT_DAYS
    )

    let pickUpTime: TDayjs = this.globalURLParams.getTimeParam(BookingParamsVoccab.pickUpTime)
    let dropOffTime: TDayjs = this.globalURLParams.getTimeParam(BookingParamsVoccab.dropOffTime)

    if (!params[BookingParamsVoccab.dropOffTime]) {
      dropOffTime.add(MIN_RENT_DAYS, 'day')
    }

    if (!params[BookingParamsVoccab.pickUpTime] || pickUpTime.isBefore(this.today.add(2, 'hour'))) {
      pickUpTime = this.today.add(2, 'hour')
      // @TODO: run url update here
    }

    if (dropOffTime.diff(pickUpTime, 'day') < MIN_RENT_DAYS) {
      dropOffTime = dayjs(pickUpTime).add(MIN_RENT_DAYS, 'day')
      // @TODO: run url update here
    }

    return {
      pickUpTime,
      dropOffTime
    }
  }

  getParams(): TBookingParams {
    const params = this.globalURLParams.getParams()

    return {
      ...this.getDay(),
      locations: {
        ...this.getLocations()
      },
      isDifferentReturnLocation: Boolean(params[BookingParamsVoccab.isDifferentReturnLocation])
    }
  }
}
